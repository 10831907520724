import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@providers/Auth';

type TPublicOnlyRoute = { children: React.ReactElement };

const PublicOnlyRoute: React.FC<TPublicOnlyRoute> = ({ children }) => {
  const { authState } = useAuth();

  if (authState) {
    return <Navigate to="/skills" replace />;
  }

  return children;
};

export default PublicOnlyRoute;
