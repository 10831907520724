import { useQuery } from 'react-query';

import type { Subtopic } from '@entities/subtopic';

import { getSubtopic } from '@services/subtopics';

export function getSubtopicQueryKey(subtopicId: string) {
  return ['subtopic', subtopicId];
}

export const getSubtopicQueryFn = (subtopicId: string) => {
  return () => getSubtopic(subtopicId);
};

const useSubtopicData = (
  subtopicId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Subtopic>(
    getSubtopicQueryKey(subtopicId || ''),
    getSubtopicQueryFn(subtopicId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
      enabled: !!subtopicId,
    },
  );
};

export default useSubtopicData;
