import styled from 'styled-components';

export const Content = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: 440px;
  padding: 100px 100px;
  border-radius: 16px;
  box-shadow: 0px 0.2vh 0.5vw rgba(0, 0, 0, 0.2);
`;

export const LogoContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export const LogoQuark = styled.img`
  height: 80%;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 10px 0px;
`;
