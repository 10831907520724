import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useQueryClient } from 'react-query';

import { localSigIn } from '@services/auth';
import { getUserInfos } from '@services/users';
import StorageKeys from '@constants/StorageKeys';
import GenericAction from '@store/reducers/constants';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { storageGet, storageSet, storageDelete } from '@services/storage';

import useAppToast from '@hooks/useAppToast';
import useAppDispatch from '@hooks/useAppDispatch';

import type { JwtTokens } from '@services/auth.d';
import type { AuthData, AuthState, LocalSignInCredentials } from './auth.d';

type TAuthProvider = { children: React.ReactNode };
const AuthContext = createContext<AuthData>({} as AuthData);

export const AuthProvider: React.FC<TAuthProvider> = ({ children }) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { errorToast } = useAppToast();

  const [authState, setAuthState] = useState<AuthState | null | undefined>(() =>
    storageGet<AuthState>(StorageKeys.AUTH_USER),
  );

  async function signIn(tokens: JwtTokens) {
    storageSet(StorageKeys.AUTH_TOKENS, tokens);

    const user = await getUserInfos();
    storageSet(StorageKeys.AUTH_USER, user);

    setAuthState({ user });
  }

  const signOut = useCallback(async () => {
    storageDelete(StorageKeys.AUTH_USER);
    storageDelete(StorageKeys.AUTH_TOKENS);

    setAuthState(undefined);

    queryClient.clear();
    dispatch({ type: GenericAction.LOGOUT });
  }, [queryClient, dispatch]);

  useEffect(() => {
    const tokens = storageGet<JwtTokens>(StorageKeys.AUTH_TOKENS);
    if (tokens) {
      signIn(tokens).catch(signOut);
    }
  }, [signOut]);

  const localSignIn = useCallback(
    (credentials: LocalSignInCredentials) => {
      return new Promise<void>((resolve, reject) => {
        localSigIn(credentials)
          .then(async tokens => {
            await signIn(tokens);
          })
          .then(resolve)
          .catch(err => {
            if (err instanceof QuarkApiError) {
              errorToast({ message: err.message });
            } else {
              errorToast({ message: 'Algo inesperado aconteceu' });
            }

            reject();
          });
      });
    },
    [errorToast],
  );

  const contextValue = useMemo(
    () => ({
      localSignIn,
      authState,
      signOut,
    }),
    [localSignIn, authState, signOut],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export function useAuth(): AuthData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
