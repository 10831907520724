import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import HeaderSkill from '@molecules/HeaderSkill';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import useTopicsData from '@hooks/queries/useTopicsData';
import useSkillData from '@hooks/queries/useSkillData';
import ListTopics from '@pages/Topics/ListTopics';

const ListTopic: React.FC = () => {
  const navigate = useNavigate();
  const { skillId } = useParams();

  const { data: topics = [] } = useTopicsData(skillId);
  const { data: skill = null } = useSkillData(skillId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/topics', text: skill?.title || '' },
        ]}
      />
      <HeaderSkill
        title={skill?.title || ''}
        hasAddButton
        addFunction={() => navigate(`topic/add`)}
      />
      <ListTopics data={topics} />
    </LayoutContent>
  );
};

export default ListTopic;
