/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum SidebarDropdown {
  INSTITUTIONS,
  COURSES,
  CLASSES,
  USERS,
}

export interface SidebarReducerState {
  dropdown: {
    institutions: boolean;
    classes: boolean;
    courses: boolean;
    users: boolean;
  };
  filter: {
    courses: string[];
  };
}

const initialState: SidebarReducerState = {
  dropdown: {
    institutions: false,
    classes: true,
    courses: true,
    users: true,
  },
  filter: {
    courses: [],
  },
};

const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setCourseFilter(state, action: PayloadAction<string[]>) {
      state.filter.courses = action.payload;
    },
    clearCourseFilter(state) {
      state.filter.courses = [];
    },
    onDropdownExpand(state, action: PayloadAction<SidebarDropdown>) {
      switch (action.payload) {
        case SidebarDropdown.INSTITUTIONS:
          state.dropdown.institutions = true;
          break;
        case SidebarDropdown.CLASSES:
          state.dropdown.classes = true;
          break;
        case SidebarDropdown.COURSES:
          state.dropdown.courses = true;
          break;
        case SidebarDropdown.USERS:
          state.dropdown.users = true;
          break;

        default:
          break;
      }
    },
    onDropdownRetract(state, action: PayloadAction<SidebarDropdown>) {
      switch (action.payload) {
        case SidebarDropdown.INSTITUTIONS:
          state.dropdown.institutions = false;
          break;
        case SidebarDropdown.CLASSES:
          state.dropdown.classes = false;
          break;
        case SidebarDropdown.COURSES:
          state.dropdown.courses = false;
          break;
        case SidebarDropdown.USERS:
          state.dropdown.users = false;
          break;

        default:
          break;
      }
    },
  },
});

export const {
  onDropdownExpand,
  onDropdownRetract,
  setCourseFilter,
  clearCourseFilter,
} = sidebar.actions;
export default sidebar.reducer;
