import Card from '@molecules/Card';
import React from 'react';
import formatDate from '@utils/formatDate';
import { ListSubtopicContent } from './styles';

import type { ListSubtopicProps } from './listSubtopics.d';

const ListSubtopics: React.FC<ListSubtopicProps> = ({ data }) => {
  return (
    <ListSubtopicContent>
      {data.map(subtopic => (
        <Card
          key={subtopic.id}
          type="subtopic"
          id={subtopic.id}
          titleSkill={subtopic.title}
          createdAt={formatDate(subtopic.createdAt)}
          editedAt={formatDate(subtopic.updatedAt)}
          qtyLearningObject={subtopic.qtyLearningObject}
        />
      ))}
    </ListSubtopicContent>
  );
};

export default ListSubtopics;
