import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import LayoutContent from '@newAtoms/LayoutContent';
import { useParams } from 'react-router-dom';
import AddLearningObjects from '@pages/LearningObjects/AddLearningObjects';

const AddLearningObject: React.FC = () => {
  const { subtopicId } = useParams();

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/add-topic', text: 'Adicionar Objeto de Aprendizagem' },
        ]}
      />
      <AddLearningObjects subtopicId={subtopicId} />
    </LayoutContent>
  );
};

export default AddLearningObject;
