import React from 'react';
import logoQuark from '@common/svg/quark-logo.svg';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Content, Logo, Container, MobileIcon } from './styles';
import type { HeaderProps } from './header.d';

const Header: React.FC<HeaderProps> = ({ canGoBack }) => {
  const navigate = useNavigate();

  const toggleHome = () => {
    navigate('/skills');
  };

  return (
    <Content>
      <Container>
        {canGoBack ? (
          <MobileIcon onClick={() => navigate(-1)}>
            <FaChevronLeft />
          </MobileIcon>
        ) : null}
        <Logo src={logoQuark} onClick={toggleHome} />
      </Container>
    </Content>
  );
};

export default Header;
