import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import HeaderSkill from '@molecules/HeaderSkill';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import ListLearningObjects from '@pages/LearningObjects/ListLearningObjects';
import useSubtopicData from '@hooks/queries/useSubtopicData';
import useLearningObjectsData from '@hooks/queries/useLearningObjectsData';
import useSubtopicQuizData from '@hooks/queries/useSubtopicQuizData';

const ListLearningObject: React.FC = () => {
  const navigate = useNavigate();
  const { subtopicId } = useParams();

  const { data: quiz = null } = useSubtopicQuizData(subtopicId);
  const { data: learningObjects = [] } = useLearningObjectsData(subtopicId);
  const { data: subtopic = null } = useSubtopicData(subtopicId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/learning-object', text: subtopic?.title || '' },
        ]}
      />
      <HeaderSkill
        title={subtopic?.title || ''}
        hasAddButton
        isListObjectLearning
        addQuizFunction={() => navigate('quiz/add')}
        addFunction={() => navigate(`learning-object/add`)}
      />
      <ListLearningObjects quiz={quiz} data={learningObjects} />
    </LayoutContent>
  );
};

export default ListLearningObject;
