import { AxiosError } from 'axios';

import type { QuarkApiErrorResponse } from '@apis/errors/QuarkApiError/quarkApiError.d';

import i18n from '@i18n/.';
import { GeneralErrorCode } from '@i18n/codes';

export default class QuarkApiError {
  status: number;

  code: string;

  message: string;

  originalMessage: string;

  path: string;

  timestamp: string;

  constructor(error: AxiosError<QuarkApiErrorResponse>) {
    const data = error?.response?.data;

    this.status = data?.status || 0;
    this.code = data?.code || GeneralErrorCode.NETWORK_ERROR;
    this.message = i18n.t(data?.code || GeneralErrorCode.NETWORK_ERROR);
    this.originalMessage = data?.message || error.message;
    this.path = data?.path || `/${error.config?.url || 'unreadable'}`;
    this.timestamp = data?.timestamp || new Date().toISOString();
  }
}
