import axios, { AxiosError } from 'axios';

import QuarkApiError, {
  QuarkApiErrorResponse,
} from '@apis/errors/QuarkApiError';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
  headers: { 'x-client-platform': 'web' },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  async (error: AxiosError<QuarkApiErrorResponse>) => {
    throw new QuarkApiError(error);
  },
);

export default api;
