import publicApi from '@apis/public-quark';
import QuarkApiError from '@apis/errors/QuarkApiError';

import type { JwtTokens, LocalSignInCredentials } from './auth.d';

export const localSigIn = async (credentials: LocalSignInCredentials) => {
  return new Promise<JwtTokens>((resolve, reject) => {
    publicApi
      .post<JwtTokens>('auth/authorize', credentials)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const refreshTokens = async (refreshToken: string) => {
  return new Promise<JwtTokens>((resolve, reject) => {
    publicApi
      .post<JwtTokens>('token/refresh', {
        refresh_token: refreshToken,
      })
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
