import { useQuery } from 'react-query';

import { getSubtopics } from '@services/subtopics';
import type { Subtopic } from '@entities/subtopic';

export function getSubtopicsQueryKey(topicId: string) {
  return ['subtopics', topicId];
}

export const getSubtopicsQueryFn = (topicId: string) => {
  return () => getSubtopics(topicId);
};

const useSubtopicsData = (
  topicId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Subtopic[]>(
    getSubtopicsQueryKey(topicId || ''),
    getSubtopicsQueryFn(topicId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data.sort((a, b) => a.index - b.index);
      },
      staleTime: Infinity,
      enabled: !!topicId,
    },
  );
};

export default useSubtopicsData;
