import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import TextAreaInput from '@newAtoms/TextAreaInput';
import {
  addLearningFileContent,
  addLearningTextContent,
} from '@services/learningContent';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import FormData2 from 'form-data';
import { useQueryClient } from 'react-query';
import { getLearningContentsQueryKey } from '@hooks/queries/useLearningContentsData';
import { getLearningObjectQueryKey } from '@hooks/queries/useLearningObjectData';
import type {
  AddAudioForm,
  AddLearningContentProps,
  AddTextForm,
  AddVideoForm,
} from './addLearningContent.d';
import { AddContent, ButtonContainer, InputContainer } from './styles';
import {
  AudioFormValidation,
  TextFormValidation,
  VideoFormValidation,
} from './yup.validation';

const AddLearningContents: React.FC<AddLearningContentProps> = ({
  learningObjectId,
}) => {
  const [isLoadingSubmitAudio, setIsLoadingSubmitAudio] = useState(false);
  const [isLoadingSubmitVideo, setIsLoadingSubmitVideo] = useState(false);
  const [isLoadingSubmitText, setIsLoadingSubmitText] = useState(false);
  const { successToast, errorToast } = useAppToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    handleSubmit: handleSubmitAudio,
    setValue: setValueAudio,
    getValues: getValuesAudio,
    formState: { errors: errorsAudio },
  } = useForm<AddAudioForm>({
    resolver: yupResolver(AudioFormValidation),
  });

  const {
    handleSubmit: handleSubmitVideo,
    setValue: setValueVideo,
    getValues: getValuesVideo,
    formState: { errors: errorsVideo },
  } = useForm<AddVideoForm>({
    resolver: yupResolver(VideoFormValidation),
  });

  const {
    handleSubmit: handleSubmitText,
    setValue: setValueText,
    getValues: getValuesText,
    formState: { errors: errorsText },
  } = useForm<AddTextForm>({
    resolver: yupResolver(TextFormValidation),
  });

  const onSubmitAudio = useCallback(
    async (values: AddAudioForm) => {
      setIsLoadingSubmitAudio(true);
      const formData = new FormData2();
      formData.append('type', 'AUDIO');

      if (values.audio.type === 'video/mp4') {
        const arrayBuffer = await values.audio.arrayBuffer();
        const file = new Blob([arrayBuffer], { type: 'audio/x-m4a' });

        formData.append('file', file, values.audio?.name);
      } else {
        formData.append('file', values.audio, values.audio?.name);
      }

      addLearningFileContent(learningObjectId || '', { formData })
        .then(() => {
          successToast({
            message: 'Áudio adicionado com sucesso!',
          });
          setIsLoadingSubmitAudio(false);
          queryClient.invalidateQueries(
            getLearningContentsQueryKey(learningObjectId || ''),
          );
          queryClient.invalidateQueries(
            getLearningObjectQueryKey(learningObjectId || ''),
          );
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmitAudio(false);
        });
    },
    [errorToast, queryClient, successToast, navigate, learningObjectId],
  );

  const onSubmitVideo = useCallback(
    (values: AddVideoForm) => {
      setIsLoadingSubmitVideo(true);
      const formData = new FormData2();
      formData.append('type', 'VIDEO');
      formData.append('file', values.video, values.video?.name);

      addLearningFileContent(learningObjectId || '', { formData })
        .then(() => {
          successToast({
            message: 'Vídeo adicionado com sucesso!',
          });
          setIsLoadingSubmitVideo(false);
          queryClient.invalidateQueries('learningContents');
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmitVideo(false);
        });
    },
    [errorToast, queryClient, successToast, learningObjectId, navigate],
  );

  const onSubmitText = useCallback(
    (values: AddTextForm) => {
      setIsLoadingSubmitText(true);
      addLearningTextContent(learningObjectId || '', {
        type: 'TEXT',
        content: values.text,
      })
        .then(() => {
          successToast({
            message: 'Texto adicionado com sucesso!',
          });
          setIsLoadingSubmitText(false);
          queryClient.invalidateQueries('learningContents');
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmitText(false);
        });
    },
    [errorToast, queryClient, successToast, navigate, learningObjectId],
  );

  return (
    <AddContent>
      <Text
        color="#342d68"
        text="Criar Conteúdos de Aprendizagem"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          type="file"
          accept="audio/ogg, audio/mpeg, audio/x-m4a"
          name="Audio"
          label="Adicionar Áudio"
          value={getValuesAudio('audio')}
          errorMessage={errorsAudio.audio?.message}
          onChangeText={value => setValueAudio('audio', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Adicionar Áudio"
          onClick={handleSubmitAudio(onSubmitAudio)}
          isLoading={isLoadingSubmitAudio}
        />
      </ButtonContainer>
      <InputContainer>
        <TextInput
          type="file"
          accept="video/mp4"
          name="Vídeo"
          label="Adicionar Vídeo"
          value={getValuesVideo('video')}
          errorMessage={errorsVideo.video?.message}
          onChangeText={value => {
            setValueVideo('video', value);
          }}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Adicionar Vídeo"
          onClick={handleSubmitVideo(onSubmitVideo)}
          isLoading={isLoadingSubmitVideo}
        />
      </ButtonContainer>
      <InputContainer>
        <TextAreaInput
          name="text"
          label="Adicionar Texto"
          value={getValuesText('text')}
          errorMessage={errorsText.text?.message}
          onChangeText={value => setValueText('text', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Adicionar Texto"
          onClick={handleSubmitText(onSubmitText)}
          isLoading={isLoadingSubmitText}
        />
      </ButtonContainer>
    </AddContent>
  );
};

export default AddLearningContents;
