/* eslint-disable jsx-a11y/alt-text */
import TooltipEdit from '@components/Tooltip/TooltipEdit';
import Text from '@newAtoms/Text';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import editIcon from '@icons/editar.svg';
import type { HeaderSkillProps } from './headerSkill.d';

import {
  ButtonContainer,
  CaixaConteudos,
  Content,
  DropdownConteudos,
  EditConteudo,
  EditTitleDiv,
  EmptyDiv,
  LinkConteudos,
} from './styles';

const HeaderSkill: React.FC<HeaderSkillProps> = ({
  title,
  hasAddButton = false,
  addFunction,
  addQuizFunction,
  editContentFunction,
  isListObjectLearning = false,
  isListContentLearning = false,
}) => {
  const dropdownVisivel = () => {
    const dropdown = document.getElementById('dropdown');
    dropdown?.classList.toggle('show');
  };
  if (isListContentLearning) {
    return (
      <Content>
        <EmptyDiv />
        <EditTitleDiv>
          <Text fontSize={32} fontWeight={700} color="#342d68" text={title} />
          <EditConteudo onClick={editContentFunction}>
            <img src={editIcon} />
            <TooltipEdit className="tooltip">Alterar Conteúdos</TooltipEdit>
          </EditConteudo>
        </EditTitleDiv>
        {hasAddButton ? (
          <ButtonContainer onClick={addFunction}>
            <FaPlus color="#fff" />
          </ButtonContainer>
        ) : (
          <EmptyDiv />
        )}
      </Content>
    );
  }

  if (isListObjectLearning) {
    return (
      <Content>
        <EmptyDiv />
        <Text fontSize={32} fontWeight={700} color="#342d68" text={title} />
        {hasAddButton ? (
          <CaixaConteudos onClick={dropdownVisivel}>
            <ButtonContainer>
              <FaPlus color="#fff" />
            </ButtonContainer>
            <DropdownConteudos id="dropdown">
              <LinkConteudos onClick={addFunction}>
                Adicionar Objeto de Aprendizagem
              </LinkConteudos>
              <LinkConteudos onClick={addQuizFunction}>
                Adicionar Quiz
              </LinkConteudos>
            </DropdownConteudos>
          </CaixaConteudos>
        ) : (
          <EmptyDiv />
        )}
      </Content>
    );
  }
  return (
    <Content>
      <EmptyDiv />
      <Text fontSize={32} fontWeight={700} color="#342d68" text={title} />
      {hasAddButton ? (
        <ButtonContainer onClick={addFunction}>
          <FaPlus color="#fff" />
        </ButtonContainer>
      ) : (
        <EmptyDiv />
      )}
    </Content>
  );
};

export default HeaderSkill;
