import { useQuery } from 'react-query';

import type { Skill } from '@entities/skill';

import { getSkills } from '@services/skills';

export function getSkillsQueryKey() {
  return 'skills';
}

export const getSkillsQueryFn = () => {
  return () => getSkills();
};

const useSkillsData = (onSuccess?: () => void, onError?: () => void) => {
  return useQuery<Skill[]>(getSkillsQueryKey(), getSkillsQueryFn(), {
    onSuccess,
    onError,
    select: data => {
      return data;
    },
    staleTime: Infinity,
  });
};

export default useSkillsData;
