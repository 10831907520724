import { useQuery } from 'react-query';

import type { Topic } from '@entities/topic';

import { getTopic } from '@services/topics';

export function getTopicQueryKey(topicId: string) {
  return ['topic', topicId];
}

export const getTopicQueryFn = (topicId: string) => {
  return () => getTopic(topicId);
};

const useTopicData = (
  topicId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Topic>(
    getTopicQueryKey(topicId || ''),
    getTopicQueryFn(topicId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
      enabled: !!topicId,
    },
  );
};

export default useTopicData;
