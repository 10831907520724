import useSkillsData from '@hooks/queries/useSkillsData';
import Card from '@molecules/Card';
import React from 'react';
import formatDate from '@utils/formatDate';
import { ListSkillProps } from './listSkills.d';
import { ListSkillContent } from './styles';

const ListSkills: React.FC<ListSkillProps> = () => {
  const { data: skills = [] } = useSkillsData();

  return (
    <ListSkillContent>
      {skills.map(skill => (
        <Card
          key={skill.id}
          id={skill.id}
          titleSkill={skill.title}
          corSkill={skill.color}
          createdAt={formatDate(skill.createdAt)}
          editedAt={formatDate(skill.updatedAt)}
          topicsQuantity={skill.qtyTopics}
        />
      ))}
    </ListSkillContent>
  );
};

export default ListSkills;
