import styled from 'styled-components';

export const AlternativeContainer = styled.div`
  display: flex;

  & + & {
    margin-top: 16px;
  }
`;

export const AlternativeLeftContent = styled.div`
  margin-right: 8px;
`;

export const AlternativeRightContent = styled.div``;

export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
`;
