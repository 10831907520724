import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LoadingSpinner from '@newAtoms/LoadingSpinner';
import Header from '@organisms/Header';
import { useParams } from 'react-router-dom';
import EditTopics from '@pages/Topics/EditTopics';
import LayoutContent from '@newAtoms/LayoutContent';
import useTopicData from '@hooks/queries/useTopicData';

const EditTopic: React.FC = () => {
  const { topicId, skillId } = useParams();
  const { data: topic = null } = useTopicData(topicId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/edit-topic', text: 'Editar Tópico' },
        ]}
      />
      {topic ? (
        <EditTopics data={topic} skillId={skillId || ''} />
      ) : (
        <LoadingSpinner color="#342d68" />
      )}
    </LayoutContent>
  );
};

export default EditTopic;
