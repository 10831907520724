import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import LayoutContent from '@newAtoms/LayoutContent';
import { useParams } from 'react-router-dom';
import AddLearningContents from '@pages/LearningContent/AddLearningContent';

const AddLearningContent: React.FC = () => {
  const { learningObjectId } = useParams();

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/add-topic', text: 'Adicionar Objeto de Aprendizagem' },
        ]}
      />
      <AddLearningContents learningObjectId={learningObjectId} />
    </LayoutContent>
  );
};

export default AddLearningContent;
