import QuarkApiError from '@services/apis/errors/QuarkApiError';
import privateApi from '@apis/private-quark';
import { Topic } from '@entities/topic';
import type { TopicProps } from './topics.d';

export const getTopics = async (skillId: string) => {
  return new Promise<Topic[]>((resolve, reject) => {
    privateApi
      .get<Topic[]>(`skill/${skillId}/topics`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const getTopic = async (topicId: string) => {
  return new Promise<Topic>((resolve, reject) => {
    privateApi
      .get<Topic>(`topic/${topicId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addTopic = async (request: TopicProps) => {
  return new Promise<Topic>((resolve, reject) => {
    privateApi
      .post<Topic>('topic', request)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const editTopic = async (request: TopicProps) => {
  return new Promise<Topic>((resolve, reject) => {
    privateApi
      .put<Topic>(`topic/${request.topicId}`, {
        title: request.title,
        icon: request.icon,
      })
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const deleteTopic = async (topicId: string) => {
  return new Promise((resolve, reject) => {
    privateApi
      .delete(`topic/${topicId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
