import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import TextAreaInput from '@newAtoms/TextAreaInput';
import {
  deleteObjectContent,
  editLearningFileContent,
  editLearningTextContent,
} from '@services/learningContent';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import FormData2 from 'form-data';
import ContentCard from '@molecules/ContentCard';
import useAppConfirmAlert from '@hooks/useAppConfirmAlert';
import { FaTrash } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { getLearningContentsQueryKey } from '@hooks/queries/useLearningContentsData';
import { getLearningContentQueryKey } from '@hooks/queries/useLearningContentData';
import type {
  EditAudioForm,
  EditLearningContentProps,
  EditTextForm,
  EditVideoForm,
} from './editLearningContent.d';
import {
  EditContent,
  ButtonContainer,
  InputContainer,
  CardFileContainer,
  IconDeleteButton,
  IconDeleteButtonForText,
} from './styles';
import {
  AudioFormValidation,
  TextFormValidation,
  VideoFormValidation,
} from './yup.validation';

const EditLearningContents: React.FC<EditLearningContentProps> = ({ data }) => {
  const [isLoadingSubmitAudio, setIsLoadingSubmitAudio] = useState(false);
  const [isLoadingSubmitVideo, setIsLoadingSubmitVideo] = useState(false);
  const [isLoadingSubmitText, setIsLoadingSubmitText] = useState(false);
  const { successToast, errorToast } = useAppToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    handleSubmit: handleSubmitAudio,
    setValue: setValueAudio,
    getValues: getValuesAudio,
    formState: { errors: errorsAudio },
  } = useForm<EditAudioForm>({
    resolver: yupResolver(AudioFormValidation),
  });

  const {
    handleSubmit: handleSubmitVideo,
    setValue: setValueVideo,
    getValues: getValuesVideo,
    formState: { errors: errorsVideo },
  } = useForm<EditVideoForm>({
    resolver: yupResolver(VideoFormValidation),
  });

  const {
    handleSubmit: handleSubmitText,
    setValue: setValueText,
    getValues: getValuesText,
    formState: { errors: errorsText },
  } = useForm<EditTextForm>({
    resolver: yupResolver(TextFormValidation),
  });

  const getDefaultValue = useCallback(
    (typeInput: string) => {
      return data?.contents.find(({ type }) => type === typeInput)?.content;
    },
    [data?.contents],
  );

  const getContentId = useCallback(
    (typeInput: string) => {
      return data?.contents.find(({ type }) => type === typeInput)?.id;
    },
    [data?.contents],
  );

  const onSubmitAudio = useCallback(
    (values: EditAudioForm) => {
      setIsLoadingSubmitAudio(true);
      const formData = new FormData2();
      formData.append('file', values.audio, values.audio?.name);

      editLearningFileContent(getContentId('AUDIO') || '', { formData })
        .then(() => {
          successToast({
            message: 'Áudio editado com sucesso!',
          });
          setIsLoadingSubmitAudio(false);
          queryClient.invalidateQueries(
            getLearningContentsQueryKey(data?.id || ''),
          );
          queryClient.invalidateQueries(
            getLearningContentQueryKey(getContentId('AUDIO') || ''),
          );
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmitAudio(false);
        });
    },
    [errorToast, successToast, navigate, getContentId, queryClient, data?.id],
  );

  const onSubmitVideo = useCallback(
    (values: EditVideoForm) => {
      setIsLoadingSubmitVideo(true);
      const formData = new FormData2();
      formData.append('file', values.video, values.video?.name);

      editLearningFileContent(getContentId('VIDEO') || '', { formData })
        .then(() => {
          successToast({
            message: 'Vídeo editado com sucesso!',
          });
          setIsLoadingSubmitVideo(false);
          queryClient.invalidateQueries(
            getLearningContentsQueryKey(data?.id || ''),
          );
          queryClient.invalidateQueries(
            getLearningContentQueryKey(getContentId('VIDEO') || ''),
          );
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmitVideo(false);
        });
    },
    [errorToast, queryClient, successToast, navigate, getContentId, data?.id],
  );

  const onSubmitText = useCallback(
    (values: EditTextForm) => {
      setIsLoadingSubmitText(true);
      editLearningTextContent(getContentId('TEXT') || '', {
        content: values.text,
      })
        .then(() => {
          successToast({
            message: 'Texto editado com sucesso!',
          });
          setIsLoadingSubmitText(false);
          queryClient.invalidateQueries(
            getLearningContentsQueryKey(data?.id || ''),
          );
          queryClient.invalidateQueries(
            getLearningContentQueryKey(getContentId('TEXT') || ''),
          );
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmitText(false);
        });
    },
    [errorToast, queryClient, successToast, navigate, getContentId, data?.id],
  );
  const { appConfirmAlert } = useAppConfirmAlert();

  return (
    <EditContent>
      <Text
        color="#342d68"
        text="Editar Conteúdos de Aprendizagem"
        fontWeight={700}
        fontSize={32}
      />
      <CardFileContainer>
        <IconDeleteButton
          onClick={() =>
            appConfirmAlert({
              title: 'Deletar Conteúdo',
              labelYes: 'Confirmar',
              labelNo: 'Cancelar',
              message: 'Deseja realmente apagar esse conteúdo?',
              onAccept: () => {
                deleteObjectContent(getContentId('AUDIO') || '')
                  .then(() => {
                    successToast({
                      message: 'Conteúdo removido com sucesso!',
                    });
                    navigate(-1);
                  })
                  .catch((e: QuarkApiError) => {
                    errorToast({ message: e.message });
                  });
              },
              onCancel: () => null,
            })
          }
        >
          <FaTrash color="#fff" size={16} />
        </IconDeleteButton>
        <ContentCard type="AUDIO" content={getDefaultValue('AUDIO') || ''} />
      </CardFileContainer>
      <InputContainer>
        <TextInput
          type="file"
          accept="audio/ogg, audio/mpeg, audio/mp4"
          name="Audio"
          label="Editar Áudio"
          value={getValuesAudio('audio')}
          errorMessage={errorsAudio.audio?.message}
          onChangeText={value => setValueAudio('audio', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar Áudio"
          onClick={handleSubmitAudio(onSubmitAudio)}
          isLoading={isLoadingSubmitAudio}
        />
      </ButtonContainer>
      <CardFileContainer>
        <IconDeleteButton
          onClick={() =>
            appConfirmAlert({
              title: 'Deletar Conteúdo',
              labelYes: 'Confirmar',
              labelNo: 'Cancelar',
              message: 'Deseja realmente apagar esse conteúdo?',
              onAccept: () => {
                deleteObjectContent(getContentId('VIDEO') || '')
                  .then(() => {
                    successToast({
                      message: 'Conteúdo removido com sucesso!',
                    });
                    navigate(-1);
                  })
                  .catch((e: QuarkApiError) => {
                    errorToast({ message: e.message });
                  });
              },
              onCancel: () => null,
            })
          }
        >
          <FaTrash color="#fff" size={16} />
        </IconDeleteButton>
        <ContentCard type="VIDEO" content={getDefaultValue('VIDEO') || ''} />
      </CardFileContainer>
      <InputContainer>
        <TextInput
          type="file"
          accept="video/mp4"
          name="Vídeo"
          label="Editar Vídeo"
          value={getValuesVideo('video')}
          errorMessage={errorsVideo.video?.message}
          onChangeText={value => {
            setValueVideo('video', value);
          }}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar Vídeo"
          onClick={handleSubmitVideo(onSubmitVideo)}
          isLoading={isLoadingSubmitVideo}
        />
      </ButtonContainer>
      <InputContainer>
        <IconDeleteButtonForText
          onClick={() =>
            appConfirmAlert({
              title: 'Deletar Conteúdo',
              labelYes: 'Confirmar',
              labelNo: 'Cancelar',
              message: 'Deseja realmente apagar esse conteúdo?',
              onAccept: () => {
                deleteObjectContent(getContentId('TEXT') || '')
                  .then(() => {
                    successToast({
                      message: 'Conteúdo removido com sucesso!',
                    });
                    navigate(-1);
                  })
                  .catch((e: QuarkApiError) => {
                    errorToast({ message: e.message });
                  });
              },
              onCancel: () => null,
            })
          }
        >
          <FaTrash color="#fff" size={16} />
        </IconDeleteButtonForText>
        <TextAreaInput
          name="text"
          label="Editar Texto"
          value={getValuesText('text')}
          errorMessage={errorsText.text?.message}
          onChangeText={value => setValueText('text', value)}
          defaultValue={getDefaultValue('TEXT')}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar Texto"
          onClick={handleSubmitText(onSubmitText)}
          isLoading={isLoadingSubmitText}
        />
      </ButtonContainer>
    </EditContent>
  );
};

export default EditLearningContents;
