import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f1eff8;
`;

export const AddContent = styled.div`
  width: 90vw;
  margin-bottom: 5vh;
  display: flex;
  flex: 1;
  min-height: 600px;
  align-self: center;
  background-color: #ffffff;
  padding-top: 5vh;
  padding-bottom: 2rem;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
`;

export const InputContainer = styled.div`
  width: 30%;
  max-width: 300px;
  margin-top: 10px;
  align-self: center;
`;

export const ButtonContainer = styled.div`
  width: 30%;
  max-width: 300px;
  padding: 16px 0px;
`;
