import { useParams } from 'react-router-dom';

import Header from '@organisms/Header';
import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LayoutContent from '@newAtoms/LayoutContent';
import LoadingSpinner from '@newAtoms/LoadingSpinner';

import EditQuizPage from '@pages/Quiz/EditQuiz';
import useQuizData from '@hooks/queries/useQuizData';

const EditQuiz: React.FC = () => {
  const { quizId } = useParams();

  const { data } = useQuizData(quizId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/edit-quiz', text: 'Editar Quiz' },
        ]}
      />
      {data ? <EditQuizPage {...data} /> : <LoadingSpinner color="#342d68" />}
    </LayoutContent>
  );
};

export default EditQuiz;
