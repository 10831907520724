import privateApi from '@apis/private-quark';
import QuarkApiError from '@apis/errors/QuarkApiError';

import type { Skill } from '@entities/skill';
import type { SkillProps } from './skills.d';

export const getSkills = async () => {
  return new Promise<Skill[]>((resolve, reject) => {
    privateApi
      .get<Skill[]>(`old-dashboard/skill`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const getSkill = async (skillId: string) => {
  return new Promise<Skill>((resolve, reject) => {
    privateApi
      .get<Skill>(`old-dashboard/skill/${skillId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addSkill = async (request: SkillProps) => {
  return new Promise<Skill>((resolve, reject) => {
    privateApi
      .post<Skill>('skill', request)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const editSkill = async (request: SkillProps) => {
  return new Promise<Skill>((resolve, reject) => {
    privateApi
      .put<Skill>(`skill/${request.skillId}`, {
        title: request.title,
        color: request.color,
      })
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const deleteSkill = async (skillId: string) => {
  return new Promise((resolve, reject) => {
    privateApi
      .delete(`skill/${skillId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
