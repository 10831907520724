import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import LayoutContent from '@newAtoms/LayoutContent';
import { useParams } from 'react-router-dom';
import AddQuizzes from '@pages/Quiz/AddQuiz/addQuiz';

const AddQuiz: React.FC = () => {
  const { subtopicId } = useParams();

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/add-quiz', text: 'Adicionar Quiz' },
        ]}
      />
      <AddQuizzes subtopicId={subtopicId} />
    </LayoutContent>
  );
};

export default AddQuiz;
