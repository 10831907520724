import QuarkApiError from '@services/apis/errors/QuarkApiError';
import privateApi from '@apis/private-quark';

import type { LearningContent } from '@entities/learningContent.d';
import type { LearningContentProps } from './learningContent.d';

export const getLearningContent = async (learningObjectId: string) => {
  return new Promise<LearningContent>((resolve, reject) => {
    privateApi
      .get<LearningContent>(`old-dashboard/learning-object/${learningObjectId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addLearningTextContent = async (
  learningObjectId: string,
  learningData: LearningContentProps,
) => {
  return new Promise<LearningContentProps>((resolve, reject) => {
    privateApi
      .post<LearningContentProps>(
        `learning-object/${learningObjectId}/content`,
        learningData,
      )
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addLearningFileContent = async (
  learningObjectId: string,
  learningData: LearningContentProps,
) => {
  return new Promise<LearningContentProps>((resolve, reject) => {
    privateApi
      .post<LearningContentProps>(
        `learning-object/${learningObjectId}/content`,
        learningData.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        },
      )
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const editLearningTextContent = async (
  learningContentId: string,
  learningData: LearningContentProps,
) => {
  return new Promise<LearningContentProps>((resolve, reject) => {
    privateApi
      .put<LearningContentProps>(
        `learning-content/${learningContentId}`,
        learningData,
      )
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const editLearningFileContent = async (
  learningContentId: string,
  learningData: LearningContentProps,
) => {
  return new Promise<LearningContentProps>((resolve, reject) => {
    privateApi
      .put<LearningContentProps>(
        `learning-content/${learningContentId}`,
        learningData.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        },
      )
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const deleteObjectContent = async (learningContentId: string) => {
  return new Promise((resolve, reject) => {
    privateApi
      .delete(`learning-content/${learningContentId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
