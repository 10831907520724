import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';

import homeStatus from '@store/reducers/homeStatus.reducer';
import sidebar from '@store/reducers/sidebar.reducer';

import type { RootState } from './types';

import GenericAction from './constants';

/* eslint-disable no-param-reassign */
/* In order to reset all reducers back to their initial states when user logout,
 * rewrite rootReducer to assign 'undefined' to state when logout
 *
 * If state passed to reducer is 'undefined', then the next state reducer returns
 * will be its initial state instead; since we have assigned it as the default value
 * of reducer's state parameter
 *   ex: const Reducer = (state = InitialState, action) => { ... }
 *
 * See: https://goo.gl/GSJ98M and combineReducers() source codes for details
 * https://gist.github.com/frankchang0125/f5d5f538ebd42c97c4eae2780555ad03
 */
const appReducer = combineReducers({ homeStatus, sidebar });

const rootReducer = (
  state: CombinedState<RootState> | undefined,
  action: AnyAction,
) => {
  if (action.type === GenericAction.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
