import React from 'react';
import { Text } from './styles';
import type { TextProps } from './text.d';

const TextTitle: React.FC<TextProps> = props => {
  const {
    text,
    color = '#4D4D4D',
    fontWeight = 500,
    fontSize = 22,
    position = 'left',
    isLabel = false,
  } = props;

  return (
    <Text
      position={position}
      color={color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      isLabel={isLabel}
    >
      {text}
    </Text>
  );
};

export default React.memo(TextTitle);
