import { useQuery } from 'react-query';
import { getLearningObject } from '@services/learningObject';
import type { LearningObject } from '@entities/learningObject';

export function getLearningObjectQueryKey(learningObjectId: string) {
  return ['learningObject', learningObjectId];
}

export const getLearningObjectQueryFn = (learningObjectId: string) => {
  return () => getLearningObject(learningObjectId);
};

const useLearningObjectData = (
  learningObjectId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<LearningObject>(
    getLearningObjectQueryKey(learningObjectId || ''),
    getLearningObjectQueryFn(learningObjectId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
      enabled: !!learningObjectId,
    },
  );
};

export default useLearningObjectData;
