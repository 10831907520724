import { useQuery } from 'react-query';
import { getLearningContent } from '@services/learningContent';
import type { LearningContent } from '@entities/learningContent';

export function getLearningContentQueryKey(learningContentId: string) {
  return ['learningContent', learningContentId];
}

export const getLearningContentQueryFn = (learningContentId: string) => {
  return () => getLearningContent(learningContentId);
};

const useLearningContentData = (
  learningContentId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<LearningContent>(
    getLearningContentQueryKey(learningContentId || ''),
    getLearningContentQueryFn(learningContentId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
      enabled: !!learningContentId,
    },
  );
};

export default useLearningContentData;
