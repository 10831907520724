import SignIn from '@pages/SignIn';
import { Routes, Route } from 'react-router-dom';
import NoPermission from '@pages/NoPermission';
import ListSkill from '@containers/Skills/skillListContainer';
import EditSkill from '@containers/Skills/skillEditContainer';
import AddSkill from '@containers/Skills/skillAddContainer';
import ListTopic from '@containers/Topics/topicListContainer';
import AddTopic from '@containers/Topics/topicAddContainer';
import EditTopic from '@containers/Topics/topicEditContainer';
import ListSubtopic from '@containers/Subtopics/subtopicListContainer';
import AddSubtopic from '@containers/Subtopics/subtopicAddContainer';
import EditSubtopic from '@containers/Subtopics/subtopicEditContainer';
import ListLearningObject from '@containers/LearningObjects/learningObjectListContainer';
import AddLearningObject from '@containers/LearningObjects/learningObjectAddContainer';
import EditLearningObject from '@containers/LearningObjects/learningObjectEditContainer';
import ListLearningContent from '@containers/LearningContents/learningContentListContainer';
import AddLearningContent from '@containers/LearningContents/learningContentAddContainer';
import EditLearningContent from '@containers/LearningContents/learningContentEditContainer';
import AddQuiz from '@containers/Quiz/QuizAddContainer';
import EditQuiz from '@containers/Quiz/quizEditContainer';

import ProtectedRoute from './protectedRoute';
import PublicOnlyRoute from './publicOnlyRoute';

const RoutesFC: React.FC = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PublicOnlyRoute>
          <SignIn />
        </PublicOnlyRoute>
      }
    />
    <Route
      path="sem-permissao"
      element={
        <ProtectedRoute>
          <NoPermission />
        </ProtectedRoute>
      }
    />
    <Route path="skills">
      <Route
        index
        element={
          <ProtectedRoute>
            <ListSkill />
          </ProtectedRoute>
        }
      />
      <Route
        path="add"
        element={
          <ProtectedRoute>
            <AddSkill />
          </ProtectedRoute>
        }
      />
      <Route
        path=":skillId/edit"
        element={
          <ProtectedRoute>
            <EditSkill />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="skill/:skillId">
      <Route
        index
        element={
          <ProtectedRoute>
            <ListTopic />
          </ProtectedRoute>
        }
      />
      <Route
        path="topic/add"
        element={
          <ProtectedRoute>
            <AddTopic />
          </ProtectedRoute>
        }
      />
      <Route
        path="topic/:topicId/edit"
        element={
          <ProtectedRoute>
            <EditTopic />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="topic/:topicId">
      <Route
        index
        element={
          <ProtectedRoute>
            <ListSubtopic />
          </ProtectedRoute>
        }
      />
      <Route
        path="subtopic/add"
        element={
          <ProtectedRoute>
            <AddSubtopic />
          </ProtectedRoute>
        }
      />
      <Route
        path="subtopic/:subtopicId/edit"
        element={
          <ProtectedRoute>
            <EditSubtopic />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="subtopic/:subtopicId">
      <Route
        index
        element={
          <ProtectedRoute>
            <ListLearningObject />
          </ProtectedRoute>
        }
      />
      <Route
        path="learning-object/add"
        element={
          <ProtectedRoute>
            <AddLearningObject />
          </ProtectedRoute>
        }
      />
      <Route
        path="quiz/add"
        element={
          <ProtectedRoute>
            <AddQuiz />
          </ProtectedRoute>
        }
      />
      <Route
        path="learning-object/:learningObjectId/edit"
        element={
          <ProtectedRoute>
            <EditLearningObject />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="quiz/:quizId">
      <Route
        index
        element={
          <ProtectedRoute>
            <EditQuiz />
          </ProtectedRoute>
        }
      />
    </Route>
    <Route path="learning-object/:learningObjectId">
      <Route
        index
        element={
          <ProtectedRoute>
            <ListLearningContent />
          </ProtectedRoute>
        }
      />
      <Route
        path="learning-content/add"
        element={
          <ProtectedRoute>
            <AddLearningContent />
          </ProtectedRoute>
        }
      />
      <Route
        path="learning-content/:learningContentId/edit"
        element={
          <ProtectedRoute>
            <EditLearningContent />
          </ProtectedRoute>
        }
      />
    </Route>
  </Routes>
);

export default RoutesFC;
