import * as yup from 'yup';

export default yup
  .string()
  .min(8, 'Senha deve ter no mínimo 8 caracteres')
  .max(32, 'Senha deve ter no máximo 32 caracteres')
  .matches(/^\S+$/g, 'Sua senha não pode conter espaços em branco')
  .matches(
    /(?!^[0-9]*$)(?!^[a-zA-Z!@#$%^&*()_+=<>?]*$)^([a-zA-Z!@#$%^&*()_+=<>?0-9]{8,32})$/g,
    'A senha deve conter ao menos uma letra e um número',
  );
