import QuarkApiError from '@services/apis/errors/QuarkApiError';
import privateApi from '@apis/private-quark';
import { LearningObject } from '@entities/learningObject.d';
import type { LearningObjectProps } from './learningObject.d';

export const getLearningObjects = async (subtopicId: string) => {
  return new Promise<LearningObject[]>((resolve, reject) => {
    privateApi
      .get<LearningObject[]>(
        `old-dashboard/learning-object?subtopicId=${subtopicId}`,
      )
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const getLearningObject = async (learningObjectId: string) => {
  return new Promise<LearningObject>((resolve, reject) => {
    privateApi
      .get<LearningObject>(`old-dashboard/learning-object/${learningObjectId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addLearningObject = async (request: LearningObjectProps) => {
  return new Promise<LearningObject>((resolve, reject) => {
    privateApi
      .post<LearningObject>('learning-object', request)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const editLearningObject = async (request: LearningObjectProps) => {
  return new Promise<LearningObject>((resolve, reject) => {
    privateApi
      .put<LearningObject>(`learning-object/${request.learningObjectId}`, {
        title: request.title,
        description: request.description,
      })
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const deleteLearningObject = async (learningObjectId: string) => {
  return new Promise((resolve, reject) => {
    privateApi
      .delete(`learning-object/${learningObjectId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
