/* eslint-disable no-inline-styles/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';

import Text from '@newAtoms/Text';
import SubmitButton from '@newAtoms/SubmitButton';
import TextAreaInput from '@newAtoms/TextAreaInput';

import { ButtonContainer } from '../../styles';
import type { EditQuizQuestionProps } from './types';

import {
  AlternativeContainer,
  AlternativeLeftContent,
  AlternativeRightContent,
  Checkbox,
} from './styles';

const EditQuizQuestion: React.FC<EditQuizQuestionProps> = data => {
  const { questionId, register, getValues, setValue, unregister } = data;

  const [alternativesId, setAlternativesId] = useState<string[]>([]);

  const onAddAlternative = useCallback(() => {
    setAlternativesId(oldValue => {
      const index = oldValue.length + 1;
      const alternativeId = `new-${index}`;

      setValue(`questions.${questionId}.options.${alternativeId}.index`, index);
      return [...oldValue, alternativeId];
    });
  }, [setValue, questionId]);

  const onDeleteAlternative = useCallback(
    (id: string) => () => {
      setAlternativesId(oldValue => oldValue.filter(oldId => oldId !== id));
      unregister(`questions.${questionId}.options.${id}`);
    },
    [questionId, unregister],
  );

  useEffect(() => {
    setAlternativesId(
      Object.keys(getValues(`questions.${questionId}.options`)),
    );
  }, [questionId, getValues]);

  return (
    <>
      <Text color="#342d68" text="Questão" fontWeight={700} fontSize={24} />

      <TextAreaInput
        style={{ resize: 'vertical', maxHeight: 70 }}
        label="Qual o título da questão?"
        defaultValue={getValues(`questions.${questionId}.title`)}
        onChangeText={value => {
          setValue(`questions.${questionId}.title`, value);
        }}
      />

      <Text
        color="#342d68"
        text="Alternativas"
        fontWeight={700}
        fontSize={24}
      />

      {alternativesId.map(alternativeId => {
        return (
          <AlternativeContainer key={alternativeId}>
            <AlternativeLeftContent>
              <Checkbox
                type="checkbox"
                defaultChecked={getValues(
                  `questions.${questionId}.options.${alternativeId}.isCorrect`,
                )}
                {...register(
                  `questions.${questionId}.options.${alternativeId}.isCorrect`,
                )}
              />
            </AlternativeLeftContent>

            <TextAreaInput
              style={{ resize: 'vertical', maxHeight: 130 }}
              defaultValue={getValues(
                `questions.${questionId}.options.${alternativeId}.title`,
              )}
              onChangeText={value => {
                setValue(
                  `questions.${questionId}.options.${alternativeId}.title`,
                  value,
                );
              }}
              label=""
            />

            <AlternativeRightContent>
              <FaTrash
                size={14}
                color="#a7a7a7"
                onClick={onDeleteAlternative(alternativeId)}
              />
            </AlternativeRightContent>
          </AlternativeContainer>
        );
      })}

      <ButtonContainer>
        <SubmitButton
          type="button"
          title="Adicionar Alternativa"
          onClick={onAddAlternative}
        />
      </ButtonContainer>
    </>
  );
};

export default React.memo(EditQuizQuestion);
