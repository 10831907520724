import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LoadingSpinner from '@newAtoms/LoadingSpinner';
import Header from '@organisms/Header';
import { useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import useLearningObjectData from '@hooks/queries/useLearningObjectData';
import EditLearningObjects from '@pages/LearningObjects/EditLearningObjects';

const EditLearningObject: React.FC = () => {
  const { subtopicId, learningObjectId } = useParams();
  const { data: learningObject = null } =
    useLearningObjectData(learningObjectId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          {
            key: '/edit-learning-object',
            text: 'Editar Objeto de Aprendizagem',
          },
        ]}
      />
      {learningObject ? (
        <EditLearningObjects
          data={learningObject}
          subtopicId={subtopicId || ''}
        />
      ) : (
        <LoadingSpinner color="#342d68" />
      )}
    </LayoutContent>
  );
};

export default EditLearningObject;
