import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from '@reduxjs/toolkit';
import { QueryClient, QueryClientProvider } from 'react-query';

import rootReducer from '@store/reducers';
import { AuthProvider } from '@providers/Auth';

type TProviders = { children: React.ReactNode };

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

// TODO: Change this to configureStore soon
const store = createStore(rootReducer);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const Providers: React.FC<TProviders> = ({ children }) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <React.StrictMode>{children}</React.StrictMode>
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default Providers;
