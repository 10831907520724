import React from 'react';
import { Content } from './styles';

import type { LayoutContentProps } from './layoutContent.d';

const LayoutContent: React.FC<LayoutContentProps> = ({ children }) => {
  return <Content>{children}</Content>;
};

export default LayoutContent;
