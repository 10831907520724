import styled from 'styled-components';

import type { ContainerButtonProps } from './styles.d';

export const Container = styled.button<ContainerButtonProps>`
  padding: 16px 56px;
  border: 0px;
  justify-content: center;
  border-radius: 8px;
  display: flex;
  width: 100%;
  position: relative;
  background-color: #342d68;
  cursor: pointer;

  :hover {
    background-color: #4f5bb2;
    transition: 0.5s;
  }
`;

export const Title = styled.p<ContainerButtonProps>`
  color: ${({ textColor }) => {
    if (textColor === 'gray') {
      return '#4d4d4d';
    }

    return '#fff';
  }};

  text-align: center;
  font-family: 'Rubik';
  font-weight: 100;
  font-size: 16px;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
