import privateApi from '@apis/private-quark';
import QuarkApiError from '@apis/errors/QuarkApiError';

import type { User } from '@entities/user';

// eslint-disable-next-line import/prefer-default-export
export const getUserInfos = async () => {
  return new Promise<User>((resolve, reject) => {
    privateApi
      .get<User>('user/profile')
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
