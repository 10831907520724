import React from 'react';
import logoQuark from '@common/svg/quark-logo.svg';

import Text from '@newAtoms/Text';
import { Content, Logo } from './styles';

const NoPermission: React.FC = () => {
  return (
    <Content>
      <Logo src={logoQuark} />
      <Text color="#000" fontWeight={700} fontSize={22} text="Desculpe!" />

      <Text
        color="#000"
        fontWeight={700}
        text="Você não possui permissão para acessar essa página."
      />
    </Content>
  );
};

export default NoPermission;
