import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LoadingSpinner from '@newAtoms/LoadingSpinner';
import Header from '@organisms/Header';
import { useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import EditSubtopics from '@pages/Subtopics/EditSubtopics';
import useSubtopicData from '@hooks/queries/useSubtopicData';

const EditSubtopic: React.FC = () => {
  const { subtopicId, topicId } = useParams();
  const { data: subtopic = null } = useSubtopicData(subtopicId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/edit', text: 'Editar Subtópico' },
        ]}
      />
      {subtopic ? (
        <EditSubtopics data={subtopic} topicId={topicId || ''} />
      ) : (
        <LoadingSpinner color="#342d68" />
      )}
    </LayoutContent>
  );
};

export default EditSubtopic;
