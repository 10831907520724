import styled from 'styled-components';
import type { ContainerStyleProps, TextStyleProps } from './styles.d';

export const InputTextArea = styled.textarea<TextStyleProps>`
  font-size: 16px;
  font-family: 'Rubik';
  color: ${({ color }) => (color !== undefined ? color : '#342d68')};
  background-color: transparent;
  border-width: 0px;
  outline: none;
  width: 100%;
`;
export const InputAreaText = styled.textarea<TextStyleProps>`
  font-size: 16px;
  font-family: 'Rubik';
  color: ${({ color }) => (color !== undefined ? color : '#342d68')};
  background-color: transparent;
  border-width: 0px;
  outline: none;
  width: 100%;
`;

export const Container = styled.div<ContainerStyleProps>`
  padding: 12px 16px;
  border-radius: 8px;
  align-items: center;
  border: ${({ borderColor }) =>
    borderColor !== undefined
      ? `1px solid ${borderColor}`
      : ' 1px solid #342d68'};
  background-color: ${({ containerColor }) => containerColor || '#ffffff70'};
`;

export const ErrorText = styled.p<TextStyleProps>`
  font-size: 12px;
  font-family: 'Rubik';
  color: ${({ color }) => (color !== undefined ? color : '#342d68')};
  padding-top: 1px;
`;

export const ErrorContainer = styled.div`
  height: 12px;
  padding-left: 8px;
`;

export const LabelContainer = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
`;
