export enum AuthErrorCode {
  CPF_EXISTS = 'auth.CPF_EXISTS',
  USER_DOES_NOT_EXISTS = 'auth.USER_DOES_NOT_EXISTS',
  WRONG_CREDENTIALS = 'auth.WRONG_CREDENTIALS',
  EMAIL_EXISTS = 'auth.EMAIL_EXISTS',
  OAUTH_EXISTS = 'auth.OAUTH_EXISTS',
  OAUTH_INTERNAL_ERROR = 'auth.OAUTH_INTERNAL_ERROR',
  INVALID_JWT = 'auth.INVALID_JWT',
  INVALID_USER_ON_REQUEST = 'auth.INVALID_USER_ON_REQUEST',
  INVALID_REGISTRATION_CODE = 'auth.INVALID_REGISTRATION_CODE',
  INSUFFICIENT_PERMISSION = 'auth.INSUFFICIENT_PERMISSION',
}

export enum ClassErrorCode {
  DOES_NOT_EXISTS = 'class.DOES_NOT_EXISTS',
}

export enum RegistrationCodeErrorCode {
  ALREADY_EXISTS = 'registrationCode.ALREADY_EXISTS',
}

export enum InstitutionErrorCode {
  DOES_NOT_EXISTS = 'institution.DOES_NOT_EXISTS',
}

export enum MappingErrorCode {
  DOES_NOT_EXISTS = 'mapping.DOES_NOT_EXISTS',
  ALREADY_REPLY = 'mapping.ALREADY_REPLY',
  ALTERNATIVE_DOES_NOT_EXISTS = 'mapping.ALTERNATIVE_DOES_NOT_EXISTS',
  REPLY_DOES_NOT_EXISTS = 'mapping.REPLY_DOES_NOT_EXISTS',
  INSUFFICIENT_ANSWERS = 'mapping.INSUFFICIENT_ANSWERS',
}

export enum QuizErrorCode {
  DOES_NOT_EXISTS = 'quiz.DOES_NOT_EXISTS',
}

export enum GeneralErrorCode {
  INTERNAL_ERROR = 'general.INTERNAL_ERROR',
  NETWORK_ERROR = 'general.NETWORK_ERROR',
}

export enum ValidationErrorCode {
  WRONG_PROPERTIES = 'validation.WRONG_PROPERTIES',
}
