import styled from 'styled-components';

export const ListSkillContent = styled.div`
  flex: 1;
  padding: 32px 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  max-width: 1300px;
  align-self: center;
`;

export const Views = styled.div``;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f1eff8;
`;

export const Logo = styled.img`
  color: #fff;
  width: 200px;
  cursor: pointer;
`;
