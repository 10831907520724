import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';

import SubmitButton from '@newAtoms/SubmitButton';
import TextInput from '@newAtoms/TextInput';
import Text from '@newAtoms/Text';

import useAppToast from '@hooks/useAppToast';

import type { QuizProps, UpdateQuizDto } from '@services/quiz.d';
import { updateQuiz } from '@services/quiz';

import QuarkApiError from '@services/apis/errors/QuarkApiError';
import EditQuizQuestion from './components/Question/question';

import { AddQuizContent, ButtonContainer } from './styles';
import type { EditQuizForm, QuestionsState } from './types';

const EditQuiz: React.FC<QuizProps> = data => {
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();

  const [questions, setQuestions] = useState<QuestionsState>(
    data.questions
      .sort((a, b) => a.index - b.index)
      .reduce(
        (previous, question) => ({
          ...previous,
          [question.id]: {
            title: question.title,
            index: question.index,
            options: question.options
              .sort((a, b) => a.index - b.index)
              .reduce(
                (prev, option) => ({
                  ...prev,
                  [option.id]: {
                    index: option.index,
                    title: option.title,
                    isCorrect: option.isCorrect,
                  },
                }),
                {},
              ),
          },
        }),
        {},
      ),
  );

  const [isLoading, setIsLoading] = useState(false);

  const { register, getValues, setValue, unregister, handleSubmit } =
    useForm<EditQuizForm>({
      defaultValues: {
        title: data.title,
        questions,
      },
    });

  const onSubmit = useCallback(
    async (body: EditQuizForm) => {
      setIsLoading(true);

      const requestBody: UpdateQuizDto = {
        title: body.title,
        questions: Object.entries(body.questions).map(([key, value]) => ({
          id: key.startsWith('new') ? undefined : key,
          index: value.index,
          title: value.title,
          options: Object.entries(value.options).map(([optionKey, option]) => ({
            id: optionKey.startsWith('new') ? undefined : optionKey,
            title: option.title,
            index: option.index,
            isCorrect: option.isCorrect,
          })),
        })),
      };

      updateQuiz(data.id, requestBody)
        .then(() => {
          successToast({ message: 'Quiz editado com sucesso!' });
          setTimeout(() => {
            navigate(0);
          }, 1500);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoading(false);
        });
    },
    [data.id, navigate, successToast, errorToast],
  );

  const onDeleteQuestion = (questionId: string) => {
    setQuestions(oldValue => {
      const newValue = oldValue;
      delete newValue[questionId];
      return newValue;
    });

    unregister(`questions.${questionId}`);
  };

  const onAddQuestion = useCallback(() => {
    setQuestions(oldQuestions => {
      const index = Object.entries(oldQuestions).length + 1;
      const questionId = `new-${index}`;
      const question = {
        index,
        title: '',
        options: {},
      };

      setValue(`questions.${questionId}`, question);

      return { ...oldQuestions, [questionId]: question };
    });
  }, [setValue]);

  return (
    <AddQuizContent onSubmit={handleSubmit(onSubmit)}>
      <Text color="#342d68" text="Editar Quiz" fontWeight={700} fontSize={32} />

      <br />

      <TextInput
        label="Título do Quiz"
        defaultValue={getValues('title')}
        onChangeText={value => setValue('title', value)}
      />

      {Object.entries(questions).map(([key, value]) => {
        return (
          <React.Fragment key={key}>
            <FaTrash
              size={14}
              color="#a7a7a7"
              onClick={() => onDeleteQuestion(key)}
            />

            <EditQuizQuestion
              questionId={key}
              register={register}
              setValue={setValue}
              getValues={getValues}
              unregister={unregister}
            />
          </React.Fragment>
        );
      })}

      <br />
      <br />

      <ButtonContainer>
        <SubmitButton
          type="button"
          onClick={onAddQuestion}
          title="Adicionar Questão"
        />

        <br />

        <SubmitButton type="submit" title="Salvar" isLoading={isLoading} />
      </ButtonContainer>
    </AddQuizContent>
  );
};

export default EditQuiz;
