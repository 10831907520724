import React from 'react';
import { BCLink, BreadcrumbsFC } from './styles';
import type { BreadcrumbsProps } from './breadcrumbs.d';

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links }) => {
  return (
    <BreadcrumbsFC>
      {links.map(link => (
        <BCLink key={link.key} href={link.key}>
          {link.text}
        </BCLink>
      ))}
    </BreadcrumbsFC>
  );
};

export default Breadcrumbs;
