import React, { useCallback, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import quarkLogo from '@common/svg/quark-logo.svg';

import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useAuth } from '@providers/Auth';
import SignInFormValidationSchema from './yup.validation';
import type { SignInForm } from './signIn.d';
import {
  ButtonContainer,
  Content,
  FormContainer,
  InputContainer,
  LogoContainer,
  LogoQuark,
} from './styles';

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const { localSignIn } = useAuth();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SignInForm>({
    resolver: yupResolver(SignInFormValidationSchema),
  });

  const onSubmit = useCallback(
    (values: SignInForm) => {
      setIsLoadingSubmit(true);
      localSignIn(values)
        .then(() => {
          navigate('/skills', { replace: true });
        })
        .catch(() => setIsLoadingSubmit(false));
    },
    [localSignIn, navigate, setIsLoadingSubmit],
  );

  return (
    <Content>
      <FormContainer>
        <LogoContainer>
          <LogoQuark src={quarkLogo} />
        </LogoContainer>
        <InputContainer>
          <TextInput
            label="E-mail"
            value={getValues('email')}
            errorMessage={errors.email?.message}
            onChangeText={value => setValue('email', value)}
            {...register('email')}
          />
          <TextInput
            type="password"
            label="Senha"
            value={getValues('password')}
            errorMessage={errors.password?.message}
            onChangeText={value => setValue('password', value)}
            {...register('password')}
          />
        </InputContainer>
        <ButtonContainer>
          <SubmitButton
            title="Entrar"
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoadingSubmit}
          />
        </ButtonContainer>
      </FormContainer>
    </Content>
  );
};

export default SignIn;
