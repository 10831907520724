import { useQuery } from 'react-query';

import { getTopics } from '@services/topics';
import type { Topic } from '@entities/topic';

export function getTopicsQueryKey(skillId: string) {
  return ['topics', skillId];
}

export const getTopicsQueryFn = (skillId: string) => {
  return () => getTopics(skillId);
};

const useTopicsData = (
  skillId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Topic[]>(
    getTopicsQueryKey(skillId || ''),
    getTopicsQueryFn(skillId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data.sort((a, b) => a.index - b.index);
      },
      staleTime: Infinity,
      enabled: !!skillId,
    },
  );
};

export default useTopicsData;
