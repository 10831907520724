import React from 'react';
import type { ContentCardProps } from './contentCard.d';
import { Audio, CaixaTexto, Texto, Video } from './styles';

const ContentCard: React.FC<ContentCardProps> = ({ type = null, content }) => {
  if (type === 'TEXT') {
    return (
      <CaixaTexto>
        <Texto>{content}</Texto>
      </CaixaTexto>
    );
  }

  if (type === 'AUDIO') {
    return (
      <Audio controls controlsList="nodownload">
        <source src={content} />
      </Audio>
    );
  }

  if (type === 'VIDEO') {
    return (
      <Video controls controlsList="nodownload">
        <source src={content} />
      </Video>
    );
  }
  return null;
};

export default ContentCard;
