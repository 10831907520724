import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { deleteTopic, editTopic } from '@services/topics';
import useAppConfirmAlert from '@hooks/useAppConfirmAlert';
import { FaTrash } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { getTopicQueryKey } from '@hooks/queries/useTopicData';
import { getTopicsQueryKey } from '@hooks/queries/useTopicsData';
import EditTopicFormValidation from './yup.validation';

import type { EditTopicProps, EditTopicsForm } from './editTopics.d';

import {
  EditTopicContent,
  ButtonContainer,
  InputContainer,
  IconDeleteButton,
} from './styles';

const EditTopics: React.FC<EditTopicProps> = ({ data, skillId }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EditTopicsForm>({
    defaultValues: {
      title: data.title,
      icon: data.icon,
    },
    resolver: yupResolver(EditTopicFormValidation),
  });

  const onSubmit = useCallback(
    (values: EditTopicsForm) => {
      setIsLoadingSubmit(true);

      editTopic({
        title: values.title,
        icon: values.icon,
        topicId: data.id,
      })
        .then(() => {
          successToast({ message: 'Tópico editado com sucesso!' });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries(getTopicQueryKey(data.id || ''));
          queryClient.invalidateQueries(getTopicsQueryKey(skillId || ''));
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, skillId, queryClient, errorToast, navigate, data.id],
  );
  const { appConfirmAlert } = useAppConfirmAlert();

  return (
    <EditTopicContent>
      <IconDeleteButton
        onClick={() =>
          appConfirmAlert({
            title: 'Deletar Tópico',
            labelYes: 'Confirmar',
            labelNo: 'Cancelar',
            message: 'Deseja realmente apagar esse tópico?',
            onAccept: () => {
              deleteTopic(data.id)
                .then(() => {
                  successToast({ message: 'Tópico removido com sucesso!' });
                  navigate(-1);
                })
                .catch((e: QuarkApiError) => {
                  errorToast({ message: e.message });
                });
            },
            onCancel: () => null,
          })
        }
      >
        <FaTrash color="#fff" size={16} />
      </IconDeleteButton>
      <Text
        color="#342d68"
        text="Editar Tópico"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          defaultValue={data.title}
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
        <TextInput
          defaultValue={data.icon}
          name="icon"
          label="Ícone"
          value={getValues('icon')}
          errorMessage={errors.icon?.message}
          onChangeText={value => setValue('icon', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </EditTopicContent>
  );
};

export default EditTopics;
