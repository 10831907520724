import React, { memo } from 'react';

import LoadingSpinner from '@newAtoms/LoadingSpinner';

import { Container, TextContainer, Title } from './styles';

import type { SubmitButtonProps } from './submitButton.d';

const SubmitButton: React.FC<SubmitButtonProps> = props => {
  const {
    title = '',
    appearance,
    isLoading = false,
    textColor = 'white',
    ...rest
  } = props;

  if (isLoading) {
    return (
      <Container appearance={appearance}>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container appearance={appearance} {...rest}>
      <TextContainer>
        <Title textColor={textColor} appearance={appearance}>
          {title}
        </Title>
      </TextContainer>
    </Container>
  );
};

export default memo(SubmitButton);
