import styled from 'styled-components';
import type { TextStyleProps } from './styles.d';

export const Text = styled.p<TextStyleProps>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: 'Rubik';
  color: ${({ color }) => color};
  text-align: ${({ position }) => position};
  line-height: ${({ fontSize = 22 }) => fontSize * 1.5}px;

  @media screen and (max-width: 760px) {
    text-align: ${({ isLabel }) => (isLabel ? 'left' : 'center')};
  }
`;

export const View = {};
