import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import useSkillsData from '@hooks/queries/useSkillsData';
import HeaderSkill from '@molecules/HeaderSkill';
import { useNavigate } from 'react-router-dom';
import ListSkills from '@pages/Skills/ListSkills';
import LayoutContent from '@newAtoms/LayoutContent';

const ListSkill: React.FC = () => {
  const { data: skills = [] } = useSkillsData();
  const navigate = useNavigate();

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs links={[{ key: '/skills', text: 'Skills' }]} />
      <HeaderSkill
        title="Skills"
        hasAddButton
        addFunction={() => navigate('add')}
      />
      <ListSkills data={skills} />
    </LayoutContent>
  );
};

export default ListSkill;
