import React from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@providers/Auth';

type TProtectedRoute = { children: React.ReactElement };

const ProtectedRoute: React.FC<TProtectedRoute> = ({ children }) => {
  const { authState } = useAuth();

  if (!authState) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
