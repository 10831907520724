import styled from 'styled-components';

export const Content = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
  margin: 0px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

export const Container = styled.div`
  color: #fff;
  display: flex;
  height: 50px;
  padding: 16px 0px;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  justify-content: center;
`;

export const Logo = styled.img`
  color: #fff;
  width: 200px;
  cursor: pointer;
`;

export const MobileIcon = styled.div`
  position: absolute;
  left: 0;
  margin-left: 10vw;
  align-self: center;
  font-size: 1.8rem;
  cursor: pointer;
  color: #342d68;
`;
