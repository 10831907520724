import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LoadingSpinner from '@newAtoms/LoadingSpinner';
import Header from '@organisms/Header';
import HeaderSkill from '@molecules/HeaderSkill';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import ListLearningContents from '@pages/LearningContent/ListLearningContent';
import useLearningContentsData from '@hooks/queries/useLearningContentsData';

const ListLearningContent: React.FC = () => {
  const navigate = useNavigate();
  const { learningObjectId } = useParams();

  const { data: learningContent = null } =
    useLearningContentsData(learningObjectId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/learning-object', text: learningContent?.title || '' },
        ]}
      />
      <HeaderSkill
        title={learningContent?.title || ''}
        hasAddButton
        isListContentLearning
        editContentFunction={() =>
          navigate(`learning-content/${learningContent?.id}/edit`)
        }
        addFunction={() => navigate(`learning-content/add`)}
      />
      {learningContent ? (
        <ListLearningContents data={learningContent} />
      ) : (
        <LoadingSpinner color="#342d68" />
      )}
    </LayoutContent>
  );
};

export default ListLearningContent;
