import { useQuery } from 'react-query';

import type { Quiz } from '@entities/quiz';

import { getQuiz } from '@services/quiz';

export function getQuizQueryKey(Id: string) {
  return ['quiz', Id];
}

export const getQuizQueryFn = (Id: string) => {
  return () => getQuiz(Id);
};

const useQuizData = (
  id?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Quiz>(getQuizQueryKey(id || ''), getQuizQueryFn(id || ''), {
    onSuccess,
    onError,
    select: data => data,
    enabled: !!id,
    staleTime: Infinity,
  });
};

export default useQuizData;
