import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import AddSkills from '@pages/Skills/AddSkills';
import LayoutContent from '@newAtoms/LayoutContent';

const AddSkill: React.FC = () => {
  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/add-skill', text: 'Adicionar Skill' },
        ]}
      />
      <AddSkills />
    </LayoutContent>
  );
};

export default AddSkill;
