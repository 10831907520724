import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LoadingSpinner from '@newAtoms/LoadingSpinner';
import Header from '@organisms/Header';
import { useParams } from 'react-router-dom';
import useSkillData from '@hooks/queries/useSkillData';
import EditSkills from '@pages/Skills/EditSkills';
import LayoutContent from '@newAtoms/LayoutContent';

const EditSkill: React.FC = () => {
  const { skillId } = useParams();
  const { data: skill = null } = useSkillData(skillId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/edit-skill', text: 'Editar Skill' },
        ]}
      />
      {skill ? <EditSkills data={skill} /> : <LoadingSpinner color="#342d68" />}
    </LayoutContent>
  );
};

export default EditSkill;
