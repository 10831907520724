import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { addSubtopic } from '@services/subtopics';
import { useQueryClient } from 'react-query';
import { getSubtopicsQueryKey } from '@hooks/queries/useSubtopicsData';
import AddSubtopicsFormValidation from './yup.validation';

import { AddSubtopicsContent, ButtonContainer, InputContainer } from './styles';

import type { AddSubtopicProps, AddSubtopicsForm } from './addSubtopics.d';

const AddSubtopics: React.FC<AddSubtopicProps> = ({ topicId }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddSubtopicsForm>({
    resolver: yupResolver(AddSubtopicsFormValidation),
  });

  const onSubmit = useCallback(
    (values: AddSubtopicsForm) => {
      setIsLoadingSubmit(true);

      addSubtopic({
        title: values.title,
        index: Number(values.index),
        topicId,
      })
        .then(() => {
          successToast({ message: 'Subtópico adicionado com sucesso!' });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries(getSubtopicsQueryKey(topicId || ''));
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, queryClient, errorToast, navigate, topicId],
  );

  return (
    <AddSubtopicsContent>
      <Text
        color="#342d68"
        text="Criar Subtópico"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
        <TextInput
          name="index"
          label="Posição"
          value={getValues('index')}
          errorMessage={errors.index?.message}
          onChangeText={value => setValue('index', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Criar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </AddSubtopicsContent>
  );
};

export default AddSubtopics;
