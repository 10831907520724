import { useQuery } from 'react-query';
import { getLearningContent } from '@services/learningContent';
import type { LearningContent } from '@entities/learningContent';

export function getLearningContentsQueryKey(learningObjectId: string) {
  return ['learningContents', learningObjectId];
}

export const getLearningContentsQueryFn = (learningObjectId: string) => {
  return () => getLearningContent(learningObjectId);
};

const useLearningContentsData = (
  learningObjectId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<LearningContent>(
    getLearningContentsQueryKey(learningObjectId || ''),
    getLearningContentsQueryFn(learningObjectId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
      enabled: !!learningObjectId,
    },
  );
};

export default useLearningContentsData;
