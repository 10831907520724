function storageGet<T>(key: string): T | undefined {
  const stringified = localStorage.getItem(`@QuarkDashboard:${key}`);
  if (!stringified) {
    return undefined;
  }

  return JSON.parse(stringified) as T;
}

function storageSet(key: string, data: object | string): void {
  localStorage.setItem(`@QuarkDashboard:${key}`, JSON.stringify(data));
}

function storageDelete(key: string): void {
  localStorage.removeItem(`@QuarkDashboard:${key}`);
}

export { storageGet, storageSet, storageDelete };
