/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SelectedInstitution = {
  id: string;
  name: string;
};

type SelectedClass = {
  id: string;
  name: string;
};

export interface HomeStatusReducerState {
  headerTitle: string;
  selectedInstitution?: SelectedInstitution;
  selectedClass?: SelectedClass;
  selectedUserId?: string;
}

const initialState: HomeStatusReducerState = {
  headerTitle: '',
  selectedInstitution: undefined,
  selectedClass: undefined,
  selectedUserId: undefined,
};

const homeStatus = createSlice({
  name: 'homeStatus',
  initialState,
  reducers: {
    selectInstitution(state, action: PayloadAction<SelectedInstitution>) {
      state.selectedInstitution = action.payload;
    },
    selectClass(state, action: PayloadAction<SelectedClass>) {
      state.selectedClass = action.payload;
    },
    unselectClass(state) {
      state.selectedClass = undefined;
    },
    selectUserId(state, action: PayloadAction<string>) {
      state.selectedUserId = action.payload;
    },
    unselectUserId(state) {
      state.selectedUserId = undefined;
    },
    setHeaderTitle(state, action: PayloadAction<string>) {
      state.headerTitle = action.payload;
    },
  },
});

export const {
  selectInstitution,
  selectClass,
  unselectClass,
  selectUserId,
  unselectUserId,
  setHeaderTitle,
} = homeStatus.actions;
export default homeStatus.reducer;
