import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import TextAreaInput from '@newAtoms/TextAreaInput';
import {
  deleteLearningObject,
  editLearningObject,
} from '@services/learningObject';
import useAppConfirmAlert from '@hooks/useAppConfirmAlert';
import { FaTrash } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { getLearningObjectQueryKey } from '@hooks/queries/useLearningObjectData';
import { getLearningObjectsQueryKey } from '@hooks/queries/useLearningObjectsData';
import type {
  EditLearningObjectForm,
  EditLearningObjectProps,
} from './editLearningObjects.d';
import EditObjectFormValidation from './yup.validation';

import {
  AddObjectContent,
  ButtonContainer,
  IconDeleteButton,
  InputContainer,
} from './styles';

const EditLearningObjects: React.FC<EditLearningObjectProps> = ({
  data,
  subtopicId,
}) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EditLearningObjectForm>({
    defaultValues: {
      title: data.title,
      description: data.description,
    },
    resolver: yupResolver(EditObjectFormValidation),
  });

  const onSubmit = useCallback(
    (values: EditLearningObjectForm) => {
      setIsLoadingSubmit(true);

      editLearningObject({
        title: values.title,
        description: values.description,
        learningObjectId: data.id,
      })
        .then(() => {
          successToast({
            message: 'Objeto de Aprendizagem editado com sucesso!',
          });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries(
            getLearningObjectQueryKey(data.id || ''),
          );
          queryClient.invalidateQueries(
            getLearningObjectsQueryKey(subtopicId || ''),
          );
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, subtopicId, errorToast, navigate, data.id, queryClient],
  );
  const { appConfirmAlert } = useAppConfirmAlert();

  return (
    <AddObjectContent>
      <IconDeleteButton
        onClick={() =>
          appConfirmAlert({
            title: 'Deletar Objeto de Aprendizagem',
            labelYes: 'Confirmar',
            labelNo: 'Cancelar',
            message: 'Deseja realmente apagar esse objeto de aprendizagem?',
            onAccept: () => {
              deleteLearningObject(data.id)
                .then(() => {
                  successToast({
                    message: 'Objeto de Aprendizagem removido com sucesso!',
                  });
                  navigate(-1);
                })
                .catch((e: QuarkApiError) => {
                  errorToast({ message: e.message });
                });
            },
            onCancel: () => null,
          })
        }
      >
        <FaTrash color="#fff" size={16} />
      </IconDeleteButton>
      <Text
        color="#342d68"
        text="Editar Objeto de Aprendizagem"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
          defaultValue={data.title}
        />
        <TextAreaInput
          name="description"
          label="Descrição"
          value={getValues('description')}
          errorMessage={errors.description?.message}
          onChangeText={value => setValue('description', value)}
          defaultValue={data.description}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </AddObjectContent>
  );
};

export default EditLearningObjects;
