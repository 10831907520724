/* eslint-disable import/no-duplicates */
import Card from '@molecules/Card';
import React from 'react';
import formatDate from '@utils/formatDate';
import { ListLearningObjectContent } from './styles';
import { ListLearningObjectProps } from './listLearningObjects.d';

const ListLearningObjects: React.FC<ListLearningObjectProps> = ({
  data,
  quiz,
}) => {
  return (
    <ListLearningObjectContent>
      {data.map(learningObject => (
        <Card
          key={learningObject.id}
          type="learningObject"
          id={learningObject.id}
          titleSkill={learningObject.title}
          createdAt={formatDate(learningObject.createdAt)}
          editedAt={formatDate(learningObject.updatedAt)}
          contentTypes={learningObject.contentTypes}
          description={learningObject?.description}
        />
      ))}
      {quiz ? (
        <Card
          key={quiz.id}
          type="quiz"
          id={quiz.id}
          titleSkill={quiz.title}
          createdAt={formatDate(quiz.createdAt)}
          editedAt={formatDate(quiz.updatedAt)}
          questions={quiz.questions}
        />
      ) : null}
    </ListLearningObjectContent>
  );
};

export default ListLearningObjects;
