import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import TextAreaInput from '@newAtoms/TextAreaInput';
import { addLearningObject } from '@services/learningObject';
import { useQueryClient } from 'react-query';
import { getLearningObjectsQueryKey } from '@hooks/queries/useLearningObjectsData';
import type {
  AddLearningObjectForm,
  AddLearningObjectProps,
} from './addLearningObjects.d';
import AddObjectFormValidation from './yup.validation';

import { AddObjectContent, ButtonContainer, InputContainer } from './styles';

const AddLearningObjects: React.FC<AddLearningObjectProps> = ({
  subtopicId,
}) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddLearningObjectForm>({
    resolver: yupResolver(AddObjectFormValidation),
  });

  const onSubmit = useCallback(
    (values: AddLearningObjectForm) => {
      setIsLoadingSubmit(true);

      addLearningObject({
        title: values.title,
        index: Number(values.index),
        description: values.description,
        subtopicId,
      })
        .then(() => {
          successToast({
            message: 'Objeto de Aprendizagem adicionado com sucesso!',
          });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries(
            getLearningObjectsQueryKey(subtopicId || ''),
          );
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, queryClient, errorToast, navigate, subtopicId],
  );

  return (
    <AddObjectContent>
      <Text
        color="#342d68"
        text="Criar Objeto de Aprendizagem"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
        <TextInput
          name="index"
          label="Posição"
          value={getValues('index')}
          errorMessage={errors.index?.message}
          onChangeText={value => setValue('index', value)}
        />
        <TextAreaInput
          name="description"
          label="Descrição"
          value={getValues('description')}
          errorMessage={errors.description?.message}
          onChangeText={value => setValue('description', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Criar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </AddObjectContent>
  );
};

export default AddLearningObjects;
