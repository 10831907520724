import Tooltip from '@components/Tooltip';
import TooltipEdit from '@components/Tooltip/TooltipEdit';
import React from 'react';
import editIcon from '@icons/editar.svg';
import { useNavigate } from 'react-router-dom';
import bookIcon from '@icons/book.svg';
import videoIcon from '@icons/video.svg';
import audioIcon from '@icons/microphone.svg';
import quizIcon from '@icons/quiz.svg';
import type { CardProps } from './card.d';
import {
  CardContainer,
  CardData,
  CardDatas,
  CardDataTitle,
  CardEdit,
  CardLink,
  CardTitle,
  CardTopicos,
  CorSkill,
  Icon,
  Image,
  ObjetoDatas,
  ObjetoDeAprendizagem,
  ObjetoDescricao,
  ObjetoEdit,
  ObjetoIcons,
  ObjetoTitle,
  Rodape,
  TooltipObjeto,
} from './styles';

const Card: React.FC<CardProps> = ({
  id,
  type = 'skill',
  corSkill,
  createdAt,
  editedAt,
  titleSkill,
  topicsQuantity,
  qtyLearningObject,
  contentTypes,
  description,
  questions,
}) => {
  const navigate = useNavigate();
  if (type === 'quiz') {
    return (
      <ObjetoDeAprendizagem>
        <ObjetoTitle>
          <CardLink onClick={() => navigate(`/quiz/${id}`)}>
            {titleSkill}
            <TooltipObjeto className="tooltipTitulo">
              {titleSkill}
            </TooltipObjeto>
          </CardLink>
        </ObjetoTitle>
        <ObjetoEdit className="editar" onClick={() => navigate(`/quiz/${id}`)}>
          <Image src={editIcon} />
          <TooltipEdit className="tooltip">Editar Quiz</TooltipEdit>
        </ObjetoEdit>
        <CardTopicos>{questions && questions.length} Questões</CardTopicos>

        <Rodape>
          <ObjetoDatas>
            <CardDataTitle>Criado em</CardDataTitle>
            <CardData>{createdAt}</CardData>
          </ObjetoDatas>
          <ObjetoDatas>
            <CardDataTitle>Editado em</CardDataTitle>
            <CardData>{editedAt}</CardData>
          </ObjetoDatas>
          <ObjetoIcons>
            <Icon src={quizIcon} />
          </ObjetoIcons>
        </Rodape>
      </ObjetoDeAprendizagem>
    );
  }
  if (type === 'learningObject') {
    return (
      <ObjetoDeAprendizagem>
        <ObjetoTitle>
          <CardLink onClick={() => navigate(`/learning-object/${id}`)}>
            {titleSkill}
            <TooltipObjeto className="tooltipTitulo">
              {titleSkill}
            </TooltipObjeto>
          </CardLink>
        </ObjetoTitle>
        <ObjetoEdit
          className="editar"
          onClick={() => navigate(`learning-object/${id}/edit`)}
        >
          <Image src={editIcon} />
          <TooltipEdit className="tooltip">Editar Tópico</TooltipEdit>
        </ObjetoEdit>

        <ObjetoDescricao>{description}</ObjetoDescricao>
        <Rodape>
          <ObjetoDatas>
            <CardDataTitle>Criado em</CardDataTitle>
            <CardData>{createdAt}</CardData>
          </ObjetoDatas>
          <ObjetoDatas>
            <CardDataTitle>Editado em</CardDataTitle>
            <CardData>{editedAt}</CardData>
          </ObjetoDatas>
          <ObjetoIcons>
            {contentTypes?.map(content => {
              if (content === 'TEXT') {
                return <Icon src={bookIcon} />;
              }
              if (content === 'AUDIO') {
                return <Icon src={audioIcon} />;
              }
              if (content === 'VIDEO') {
                return <Icon src={videoIcon} />;
              }
              if (content === 'QUIZ') {
                return <Icon src={quizIcon} />;
              }
              return null;
            })}
          </ObjetoIcons>
        </Rodape>
      </ObjetoDeAprendizagem>
    );
  }
  if (type === 'subtopic') {
    return (
      <CardContainer>
        <CardTitle>
          <CardLink onClick={() => navigate(`/subtopic/${id}`)}>
            {titleSkill}
            <Tooltip className="tooltipTitulo">{titleSkill}</Tooltip>
          </CardLink>
        </CardTitle>
        <CardEdit
          className="editar"
          onClick={() => navigate(`subtopic/${id}/edit`)}
        >
          <Image src={editIcon} />
          <TooltipEdit className="tooltip">Editar Tópico</TooltipEdit>
        </CardEdit>

        <CardTopicos>{qtyLearningObject} Objetos</CardTopicos>

        <CardDatas>
          <CardDataTitle>Criado em</CardDataTitle>
          <CardDataTitle>Editado em</CardDataTitle>

          <CardData>{createdAt}</CardData>
          <CardData>{editedAt}</CardData>
        </CardDatas>
      </CardContainer>
    );
  }

  if (type === 'topic') {
    return (
      <CardContainer>
        <CardTitle>
          <CardLink onClick={() => navigate(`/topic/${id}`)}>
            {titleSkill}
            <Tooltip className="tooltipTitulo">{titleSkill}</Tooltip>
          </CardLink>
        </CardTitle>
        <CardEdit
          className="editar"
          onClick={() => navigate(`topic/${id}/edit`)}
        >
          <Image src={editIcon} />
          <TooltipEdit className="tooltip">Editar Tópico</TooltipEdit>
        </CardEdit>

        <CardTopicos>{topicsQuantity} Subtópicos</CardTopicos>

        <CardDatas>
          <CardDataTitle>Criado em</CardDataTitle>
          <CardDataTitle>Editado em</CardDataTitle>

          <CardData>{createdAt}</CardData>
          <CardData>{editedAt}</CardData>
        </CardDatas>
      </CardContainer>
    );
  }
  return (
    <CardContainer>
      <CorSkill corSkill={corSkill} />
      <CardTitle>
        <CardLink onClick={() => navigate(`/skill/${id}`)}>
          {titleSkill}
          <Tooltip className="tooltipTitulo">{titleSkill}</Tooltip>
        </CardLink>
      </CardTitle>
      <CardEdit
        className="editar"
        onClick={() => navigate(`/skills/${id}/edit`)}
      >
        <Image src={editIcon} />
        <TooltipEdit className="tooltip">Editar Skill</TooltipEdit>
      </CardEdit>

      <CardTopicos>{topicsQuantity} Tópicos</CardTopicos>

      <CardDatas>
        <CardDataTitle>Criado em</CardDataTitle>
        <CardDataTitle>Editado em</CardDataTitle>

        <CardData>{createdAt}</CardData>
        <CardData>{editedAt}</CardData>
      </CardDatas>
    </CardContainer>
  );
};

export default Card;
