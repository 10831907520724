import * as yup from 'yup';

export const AudioFormValidation = yup
  .object({
    audio: yup.mixed().required('Insira um áudio'),
  })
  .required();

export const VideoFormValidation = yup
  .object({
    video: yup.mixed().required('Insira um vídeo'),
  })
  .required();

export const TextFormValidation = yup
  .object({
    text: yup.string().required('Insira um text'),
  })
  .required();
