import styled from 'styled-components';

export const EditSubtopicsContent = styled.div`
  width: 90vw;
  min-height: 65vh;
  align-self: center;
  background-color: #ffffff;
  padding-top: 5vh;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
`;

export const InputContainer = styled.div`
  width: 30%;
  max-width: 300px;
  margin-top: 40px;
  align-self: center;
`;

export const ButtonContainer = styled.div`
  width: 30%;
  max-width: 300px;
  padding: 16px 0px;
`;

export const IconDeleteButton = styled.div`
  max-height: 40px;
  max-width: 40px;
  background-color: #7e779e;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 40px;
  margin-top: 40px;
  padding: 10px;
`;
