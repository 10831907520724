import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import HeaderSkill from '@molecules/HeaderSkill';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import ListSubtopics from '@pages/Subtopics/ListSubtopics';
import useSubtopicsData from '@hooks/queries/useSubtopicsData';
import useTopicData from '@hooks/queries/useTopicData';

const ListSubtopic: React.FC = () => {
  const { topicId } = useParams();
  const navigate = useNavigate();
  const { data: subtopics = [] } = useSubtopicsData(topicId);
  const { data: topic = null } = useTopicData(topicId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/subtopics', text: topic?.title || '' },
        ]}
      />
      <HeaderSkill
        title={topic?.title || ''}
        hasAddButton
        addFunction={() => navigate('subtopic/add')}
      />
      <ListSubtopics data={subtopics} />
    </LayoutContent>
  );
};

export default ListSubtopic;
