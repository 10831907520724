import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import LayoutContent from '@newAtoms/LayoutContent';
import AddSubtopics from '@pages/Subtopics/AddSubtopics';
import { useParams } from 'react-router-dom';

const AddSubtopic: React.FC = () => {
  const { topicId } = useParams();

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/add-subtopic', text: 'Adicionar Subtópico' },
        ]}
      />
      <AddSubtopics topicId={topicId} />
    </LayoutContent>
  );
};

export default AddSubtopic;
