import Card from '@molecules/Card';
import React from 'react';
import formatDate from '@utils/formatDate';

import { ListTopicContent } from './styles';

import type { ListTopicsProps } from './listTopics.d';

const ListTopics: React.FC<ListTopicsProps> = ({ data }) => {
  return (
    <ListTopicContent>
      {data.map(topic => (
        <Card
          key={topic.id}
          type="topic"
          id={topic.id}
          titleSkill={topic.title}
          createdAt={formatDate(topic.createdAt)}
          editedAt={formatDate(topic.updatedAt)}
          topicsQuantity={topic.qtySubtopics}
        />
      ))}
    </ListTopicContent>
  );
};

export default ListTopics;
