import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { deleteSkill, editSkill } from '@services/skills';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { FaTrash } from 'react-icons/fa';
import useAppConfirmAlert from '@hooks/useAppConfirmAlert';
import { useQueryClient } from 'react-query';
import type { EditSkillProps, EditSkillsForm } from './editSkills.d';
import EditSkillFormValidation from './yup.validation';

import {
  ButtonContainer,
  EditSkillContent,
  IconDeleteButton,
  InputContainer,
} from './styles';

const EditSkills: React.FC<EditSkillProps> = ({ data }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const { appConfirmAlert } = useAppConfirmAlert();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EditSkillsForm>({
    defaultValues: {
      color: data.color,
      title: data.title,
    },
    resolver: yupResolver(EditSkillFormValidation),
  });

  const onSubmit = useCallback(
    (values: EditSkillsForm) => {
      setIsLoadingSubmit(true);

      editSkill({
        title: values.title,
        color: values.color,
        skillId: data.id,
      })
        .then(() => {
          successToast({ message: 'Skill editada com sucesso!' });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries('skills');
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, queryClient, errorToast, navigate, data.id],
  );

  return (
    <EditSkillContent>
      <IconDeleteButton
        onClick={() =>
          appConfirmAlert({
            title: 'Deletar Skill',
            labelYes: 'Confirmar',
            labelNo: 'Cancelar',
            message: 'Deseja realmente apagar essa skill?',
            onAccept: () => {
              deleteSkill(data.id)
                .then(() => {
                  successToast({ message: 'Skill removida com sucesso!' });
                  navigate(-1);
                })
                .catch((e: QuarkApiError) => {
                  errorToast({ message: e.message });
                });
            },
            onCancel: () => null,
          })
        }
      >
        <FaTrash color="#fff" size={16} />
      </IconDeleteButton>
      <Text
        color="#342d68"
        text="Editar Skill"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          defaultValue={data.title}
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
        <TextInput
          defaultValue={data.color}
          name="color"
          label="Cor"
          type="color"
          value={getValues('color')}
          errorMessage={errors.color?.message}
          onChangeText={value => setValue('color', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </EditSkillContent>
  );
};

export default EditSkills;
