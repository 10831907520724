import { BrowserRouter as Router } from 'react-router-dom';

import Providers from '@providers/.';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';

function App() {
  return (
    <Router>
      <Providers>
        <ToastContainer />
        <Routes />
      </Providers>
    </Router>
  );
}

export default App;
