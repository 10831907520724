import * as yup from 'yup';

import password from '@common/validation/password';

export default yup
  .object({
    email: yup
      .string()
      .email('Insira um e-mail válido')
      .required('Insira um e-mail válido'),
    password,
  })
  .required();
