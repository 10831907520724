import Breadcrumbs from '@newAtoms/Breadcrumbs';
import LoadingSpinner from '@newAtoms/LoadingSpinner';
import Header from '@organisms/Header';
import { useParams } from 'react-router-dom';
import LayoutContent from '@newAtoms/LayoutContent';
import EditLearningContents from '@pages/LearningContent/EditLearningContent/editLearningContent';
import useLearningContentData from '@hooks/queries/useLearningContentData';

const EditLearningContent: React.FC = () => {
  const { learningObjectId } = useParams();
  const { data: learningContent = null } =
    useLearningContentData(learningObjectId);

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          {
            key: '/edit-learning-content',
            text: 'Editar Conteúdo de Aprendizagem',
          },
        ]}
      />
      {learningContent ? (
        <EditLearningContents data={learningContent} />
      ) : (
        <LoadingSpinner color="#342d68" />
      )}
    </LayoutContent>
  );
};

export default EditLearningContent;
