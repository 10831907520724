import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import { addQuiz } from '@services/quiz';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import AddQuizValidationForm from './yup.validation';
import type { AddQuizForm, AddQuizProps } from './addQuiz.d';

import { AddQuizContent, ButtonContainer, InputContainer } from './styles';

export interface StateProps {
  title: string;
  parentId: string;
}

const AddQuizzes: React.FC<AddQuizProps> = ({ subtopicId }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddQuizForm>({
    resolver: yupResolver(AddQuizValidationForm),
  });

  const onSubmit = useCallback(
    (values: AddQuizForm) => {
      setIsLoadingSubmit(true);
      addQuiz({ title: values.title, subtopicId: subtopicId || '' })
        .then(() => {
          successToast({
            message: 'Quiz adicionado com sucesso!',
          });
          setIsLoadingSubmit(false);
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [errorToast, successToast, navigate, subtopicId],
  );

  return (
    <AddQuizContent>
      <Text color="#342d68" text="Criar Quiz" fontWeight={700} fontSize={32} />
      <InputContainer>
        <TextInput
          name="Título"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Adicionar Quiz"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </AddQuizContent>
  );
};

export default AddQuizzes;
