import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { addSkill } from '@services/skills';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { useQueryClient } from 'react-query';
import type { AddSkillsForm } from './addSkills.d';
import AddSkillFormValidation from './yup.validation';

import { AddSkillContent, ButtonContainer, InputContainer } from './styles';

const AddSkills: React.FC = () => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddSkillsForm>({
    resolver: yupResolver(AddSkillFormValidation),
  });

  const onSubmit = useCallback(
    (values: AddSkillsForm) => {
      setIsLoadingSubmit(true);

      addSkill({
        title: values.title,
        color: values.color,
      })
        .then(() => {
          successToast({ message: 'Skill adicionada com sucesso!' });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries('skills');
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, queryClient, errorToast, navigate],
  );

  return (
    <AddSkillContent>
      <Text color="#342d68" text="Criar Skill" fontWeight={700} fontSize={32} />
      <InputContainer>
        <TextInput
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
        <TextInput
          name="color"
          label="Cor"
          type="color"
          value={getValues('color')}
          errorMessage={errors.color?.message}
          onChangeText={value => setValue('color', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Criar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </AddSkillContent>
  );
};

export default AddSkills;
