import { useCallback } from 'react';
import { toast } from 'react-toastify';

type ToastDefaultData = { title?: string; message: string };

export default function useAppToast() {
  const errorToast = useCallback((data: ToastDefaultData) => {
    toast.error(data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const successToast = useCallback((data: ToastDefaultData) => {
    toast.success(data.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  return { successToast, errorToast };
}
