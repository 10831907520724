import Tooltip from '@components/Tooltip';
import styled from 'styled-components';
import type { CardStylesProps } from './styles.d';

export const CardContainer = styled.div`
  width: 250px;
  height: 130px;
  margin: 0;
  background-color: #ffffff;
  border: 2px solid #8077a0;
  border-radius: 20px;
  transition: 0.2s;
  padding: 27px 10px 17px 10px;
  grid-area: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :hover {
    transform: scale(1.05);
    border-color: #35295e;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }

  :hover a.editar {
    visibility: visible;
    opacity: 1;
  }

  :hover div.cor {
    transform: translate(-13px, -30px);
  }
`;

export const CorSkill = styled.div<CardStylesProps>`
  content: '';
  width: 0;
  height: 0;
  border: 22px solid;
  border-color: ${({ corSkill }) => corSkill};
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-left-radius: 18px;
  position: absolute;
  box-sizing: content-box;
  transform: translate(-12px, -29px);
`;

export const CardTopicos = styled.a`
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  max-width: max-content;
  background-color: #e0dee7;
  display: flex;
  font-size: 14px;
  margin: 5px 0 0 14px;
  padding: 5px 10px;
  border-radius: 15px;
  color: #c54f70;
`;

export const CardTitle = styled.section`
  max-width: 223px;
  margin: 10px 0 0 15px;
  padding: 0;
  font-size: 18px;
  color: #35295e;
  line-height: 25px;

  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;

  :active {
    color: #342d68;
  }
`;

export const CardLink = styled.a`
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  text-decoration: none;
  color: #35295e;
  cursor: pointer;

  :hover {
    color: #342d68;
  }

  :active {
    color: #0086ca;
  }

  :hover .tooltipTitulo {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }
`;

export const CardInterno = styled.div`
  width: 250px;
  height: 115px;
  background-color: #ffffff;
  border: 2px solid #8077a0;
  border-radius: 20px;
  transition: 0.2s;
  padding: 5px 10px 10px 10px;
  grid-area: auto;

  :hover {
    transform: scale(1.05);
    border-color: #35295e;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }

  :hover a.editar {
    visibility: visible;
    opacity: 1;
  }
`;

export const CardEditInterno = styled.a`
  max-width: 35px;
  max-height: 35px;
  border-radius: 100%;
  background-color: #342d68;
  position: absolute;
  transform: translate(633%, -105%);
  justify-content: center;
  cursor: pointer;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;

  :hover {
    background-color: #c54f70;
  }

  :active {
    transition: 0s;
    background-color: #e83a69;
  }

  :hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }
`;

export const CardEdit = styled.a`
  max-width: 35px;
  max-height: 35px;
  border-radius: 100%;
  background-color: #342d68;
  position: absolute;
  transform: translate(635%, -70%);
  justify-content: center;
  cursor: pointer;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease-in-out;

  :hover {
    background-color: #c54f70;
  }

  :active {
    transition: 0s;
    background-color: #e83a69;
  }

  :hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }
`;

export const CardDataTitle = styled.div`
  content: 'Criado em';
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #342d68;
`;

export const CardDatas = styled.div`
  max-width: 250px;
  display: grid;
  margin: 15px 15px 0 15px;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 5px;
  grid-column-gap: 30px;
`;

export const CardData = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Image = styled.img``;

export const Rodape = styled.section`
  width: 95%;
  min-height: 40px;
  margin: 15px 0 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ObjetoEdit = styled(CardEdit)`
  transform: translate(1742%, -15%);
  transition: 0.5s ease-in-out;
`;

export const ObjetoIcons = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 35px;
`;

export const Icon = styled.img`
  width: 35px;
  margin: 0px 4px;
`;

export const ObjetoDeAprendizagem = styled(CardContainer)`
  width: 40em;
  min-height: 8.6em;
  height: max-content;
  margin-top: 20px;
  padding: 10px;
`;

export const ObjetoDescricao = styled.p`
  font-family: 'Rubik', sans-serif;
  max-width: 94%;
  font-weight: 400;
  margin: 15px 0 0 15px;
  font-size: 15px;
  display: flex;
  text-align: justify;
`;

export const ObjetoTitle = styled(CardTitle)`
  max-width: 92%;
`;

export const TooltipObjeto = styled(Tooltip)`
  transform: translateY(-20px);
`;

export const ObjetoDatas = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
`;
