import styled from 'styled-components';

export const Video = styled.video`
  width: 35vw;
  height: max-content;
  margin-bottom: 5vh;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  transition: 0.2s;
  outline: none;
`;

export const Audio = styled.audio`
  width: 35vw;
  margin-bottom: 5vh;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  outline: none;
`;

export const CaixaTexto = styled.section`
  width: calc(35vw - 60px);
  height: max-content;
  margin-bottom: 5vh;
  padding: 20px 30px;
  background-color: #fff;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  outline: none;

  p:first-child {
    margin-top: 0;
  }
`;

export const Texto = styled.p`
  font-family: 'Rubik';
  line-height: 25px;
  color: #000000;
  text-align: justify;
  margin-top: 20px;
  padding: 0;

  ::first-letter {
    padding-left: 25px;
  }
`;
