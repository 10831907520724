import Breadcrumbs from '@newAtoms/Breadcrumbs';
import Header from '@organisms/Header';
import LayoutContent from '@newAtoms/LayoutContent';
import AddTopics from '@pages/Topics/AddTopics';
import { useParams } from 'react-router-dom';

const AddTopic: React.FC = () => {
  const { skillId } = useParams();

  return (
    <LayoutContent>
      <Header canGoBack />
      <Breadcrumbs
        links={[
          { key: '/skills', text: 'Skills' },
          { key: '/add-topic', text: 'Adicionar Tópico' },
        ]}
      />
      <AddTopics skillId={skillId} />
    </LayoutContent>
  );
};

export default AddTopic;
