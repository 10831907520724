import Text from '@newAtoms/Text';
import React, { ChangeEvent, useCallback } from 'react';
import {
  Container,
  ErrorContainer,
  ErrorText,
  InputText,
  LabelContainer,
} from './styles';
import type { TextInputProps } from './textinput.d';

const TextInput: React.FC<TextInputProps> = props => {
  const {
    label,
    color,
    value,
    containerColor,
    borderColor,
    defaultValue,
    mask,
    errorMessage,
    onChangeText,
    ...rest
  } = props;

  const onChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { type } = rest;
      if (type === 'file') {
        onChangeText?.(event.target?.files?.[0]);
      } else {
        onChangeText?.(event.target?.value);
      }
    },
    [onChangeText, rest],
  );

  return (
    <>
      <LabelContainer>
        <Text isLabel text={label} fontSize={14} color={color || '#342d68'} />
      </LabelContainer>
      <Container containerColor={containerColor} borderColor={borderColor}>
        <InputText
          {...rest}
          color={color}
          name={label}
          onChange={onChangeValue}
          defaultValue={defaultValue}
        />
      </Container>
      <ErrorContainer>
        <ErrorText color={color}>{errorMessage}</ErrorText>
      </ErrorContainer>
    </>
  );
};

export default React.memo(TextInput);
