import styled from 'styled-components';
import { CardEdit } from '../Card/styles';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 48px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyDiv = styled.div``;

export const ButtonContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: #7e779e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background-color: #342d68;
    transition: 0.5s;
  }
`;

export const DropdownConteudos = styled.div`
  visibility: visible;
  opacity: 0;
  transition: 0.5s;
`;

export const LinkConteudos = styled.a`
  width: max-content;
  position: absolute;
  right: 0vw;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  border-radius: 16px;
  border: none;
  font-size: 16px;
  color: #ffff;
  text-decoration: none;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: #8077a0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  :hover {
    cursor: pointer;
    background-color: #342d68;
  }

  :active {
    background-color: #4f5ca6;
  }

  :last-child {
    margin-top: 60px;
  }
`;

export const CaixaConteudos = styled.section`
  .show {
    opacity: 1;
    visibility: visible;
  }
`;

export const AddConteudoBtn = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 16px;
  padding: 5px;
  background-color: #8077a0;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
    background-color: #342d68;
  }

  :active {
    background-color: #4f5ca6;
  }

  :hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }
`;

export const TooltipAdd = styled.span`
  width: max-content;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  padding: 7px 10px;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transform: translateY(-145%);
  background-color: #666666;
  display: flex;
  visibility: hidden;
  opacity: 0;

  ::after {
    content: '';
    border: 10px solid;
    border-color: #666666;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-left-radius: 3px;
    position: absolute;
    right: 0;
    transform: translate(-15px, 11px) rotate(-135deg);
  }
`;

export const EditTitleDiv = styled.div`
  position: relative;
`;

export const EditConteudo = styled(CardEdit)`
  width: max-content;
  max-height: 35px;
  visibility: visible;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 8px;
  margin-right: -50px;
  transform: translateX(20%);
`;
