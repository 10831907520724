import styled, { keyframes } from 'styled-components';
import { SpinnerStylesProps } from './styles.d';
/* eslint-disable import/prefer-default-export */

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinnerContainer = styled.div<SpinnerStylesProps>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: ${({ color }) => `3px solid ${color}`};
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
