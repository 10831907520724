/* eslint-disable global-require */
import i18n from 'i18n-js';

const translations = {
  'pt-BR': {
    auth: require('./ptBr/auth.json'),
    class: require('./ptBr/class.json'),
    general: require('./ptBr/general.json'),
    institution: require('./ptBr/institution.json'),
    mapping: require('./ptBr/mapping.json'),
    quiz: require('./ptBr/quiz.json'),
    registrationCode: require('./ptBr/registrationCode.json'),
    validation: require('./ptBr/validation.json'),
  },
};

i18n.locale = navigator.language;
i18n.translations = translations;
i18n.missingTranslation = (key: string) => key; // TODO: What do we keep here? Do we just keep the key to WE understand clearly or a generic message?
i18n.defaultLocale = 'pt-BR';
i18n.fallbacks = true;

export default i18n;
