import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { deleteSubtopic, editSubtopic } from '@services/subtopics';
import useAppConfirmAlert from '@hooks/useAppConfirmAlert';
import { FaTrash } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { getSubtopicQueryKey } from '@hooks/queries/useSubtopicData';
import { getSubtopicsQueryKey } from '@hooks/queries/useSubtopicsData';
import EditSubtopicsFormValidation from './yup.validation';

import {
  EditSubtopicsContent,
  ButtonContainer,
  InputContainer,
  IconDeleteButton,
} from './styles';

import type { EditSubtopicProps, EditSubtopicsForm } from './editSubtopics.d';

const EditSubtopics: React.FC<EditSubtopicProps> = ({ data, topicId }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EditSubtopicsForm>({
    defaultValues: {
      title: data.title,
    },
    resolver: yupResolver(EditSubtopicsFormValidation),
  });

  const onSubmit = useCallback(
    (values: EditSubtopicsForm) => {
      setIsLoadingSubmit(true);

      editSubtopic({
        title: values.title,
        subtopicId: data.id,
      })
        .then(() => {
          successToast({ message: 'Subtópico editado com sucesso!' });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries(getSubtopicQueryKey(data?.id || ''));
          queryClient.invalidateQueries(getSubtopicsQueryKey(topicId || ''));
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, topicId, queryClient, errorToast, navigate, data.id],
  );

  const { appConfirmAlert } = useAppConfirmAlert();

  return (
    <EditSubtopicsContent>
      <IconDeleteButton
        onClick={() =>
          appConfirmAlert({
            title: 'Deletar Subtópico',
            labelYes: 'Confirmar',
            labelNo: 'Cancelar',
            message: 'Deseja realmente apagar esse subtópico?',
            onAccept: () => {
              deleteSubtopic(data.id)
                .then(() => {
                  successToast({ message: 'Subtópico removido com sucesso!' });
                  navigate(-1);
                })
                .catch((e: QuarkApiError) => {
                  errorToast({ message: e.message });
                });
            },
            onCancel: () => null,
          })
        }
      >
        <FaTrash color="#fff" size={16} />
      </IconDeleteButton>
      <Text
        color="#342d68"
        text="Editar Subtópico"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
          defaultValue={data.title}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Editar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </EditSubtopicsContent>
  );
};

export default EditSubtopics;
