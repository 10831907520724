import { useQuery } from 'react-query';
import { getLearningObjects } from '@services/learningObject';
import type { LearningObject } from '@entities/learningObject';

export function getLearningObjectsQueryKey(subtopicId: string) {
  return ['learningObjects', subtopicId];
}

export const getLearningObjectsQueryFn = (subtopicId: string) => {
  return () => getLearningObjects(subtopicId);
};

const useLearningObjectsData = (
  subtopicId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<LearningObject[]>(
    getLearningObjectsQueryKey(subtopicId || ''),
    getLearningObjectsQueryFn(subtopicId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data.sort((a, b) => a.index - b.index);
      },
      staleTime: Infinity,
      enabled: !!subtopicId,
    },
  );
};

export default useLearningObjectsData;
