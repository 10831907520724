import { useCallback } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

type ConfirmAlert = {
  onAccept: () => void;
  onCancel: () => void;
  title: string;
  message: string;
  labelYes: string;
  labelNo: string;
};

export default function useAppConfirmAlert() {
  const appConfirmAlert = useCallback((data: ConfirmAlert) => {
    confirmAlert({
      title: data.title,
      message: data.message,
      buttons: [
        {
          label: data.labelYes,
          onClick: data.onAccept,
        },
        {
          label: data.labelNo,
          onClick: data.onCancel,
        },
      ],
    });
  }, []);

  return { appConfirmAlert };
}
