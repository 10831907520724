import QuarkApiError from '@services/apis/errors/QuarkApiError';
import privateApi from '@apis/private-quark';
import { Subtopic } from '@entities/subtopic';
import type { SubtopicProps } from './subtopics.d';

export const getSubtopics = async (topicId: string) => {
  return new Promise<Subtopic[]>((resolve, reject) => {
    privateApi
      .get<Subtopic[]>(`old-dashboard/subtopic?topicId=${topicId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const getSubtopic = async (subtopicId: string) => {
  return new Promise<Subtopic>((resolve, reject) => {
    privateApi
      .get<Subtopic>(`old-dashboard/subtopic/${subtopicId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addSubtopic = async (request: SubtopicProps) => {
  return new Promise<Subtopic>((resolve, reject) => {
    privateApi
      .post<Subtopic>('subtopic', request)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const editSubtopic = async (request: SubtopicProps) => {
  return new Promise<Subtopic>((resolve, reject) => {
    privateApi
      .put<Subtopic>(`subtopic/${request.subtopicId}`, {
        title: request.title,
      })
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const deleteSubtopic = async (subtopicId: string) => {
  return new Promise((resolve, reject) => {
    privateApi
      .delete(`subtopic/${subtopicId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
