import React from 'react';
import ContentCard from '@molecules/ContentCard';
import formatDate from '@utils/formatDate';
import { Datas, ListContent, TextDatas } from './styles';

import { ListLearningContentProps } from './listLearningContents.d';

const ListLearningContent: React.FC<ListLearningContentProps> = ({ data }) => {
  return (
    <>
      <Datas>
        <TextDatas>Criado em {formatDate(data?.createdAt)}</TextDatas>
        <TextDatas>Editado em {formatDate(data?.updatedAt)}</TextDatas>
      </Datas>
      <ListContent>
        {data?.contents?.map(learningContentItem => (
          <ContentCard
            id={learningContentItem.id}
            content={learningContentItem.content}
            type={learningContentItem.type}
            key={learningContentItem.id}
          />
        ))}
      </ListContent>
    </>
  );
};

export default ListLearningContent;
