import styled from 'styled-components';

export const BreadcrumbsFC = styled.section`
  display: flex;
  padding: 3vh 0vw 3vh 10vw;
  justify-content: flex-start;
`;

export const BCLink = styled.a`
  display: flex;
  font-size: 16px;
  color: #736f8a;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  text-decoration: none;

  :hover {
    color: #342d68;
  }

  :active {
    color: #4f5ca6;
  }

  ::after {
    content: '>';
    margin: 0 5px;
    cursor: default;
    color: #736f8a;
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    color: #342d68;
    font-weight: 700;
    pointer-events: none;
  }

  :last-child::after {
    content: none;
  }
`;
