import styled from 'styled-components';

export const AddSkillContent = styled.div`
  width: 90vw;
  margin-bottom: 60px;
  flex: 1;
  min-height: 380px;
  align-self: center;
  background-color: #ffffff;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
`;

export const InputContainer = styled.div`
  width: 30%;
  margin-top: 40px;
  align-self: center;
`;

export const InputCor = styled.input`
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  width: 207px;
  height: 40px;
  padding: 0 10px;
  border-radius: 16px;
  border: 2px solid #8077a0;
  color: #8077a0;

  :focus {
    border-color: #342d68;
    outline: none;
    color: #342d68;
  }

  :last-child {
    border: none;
    appearance: none;
    width: 104px;
    padding: 0;
    background-color: transparent;
  }

  :last-child::-webkit-color-swatch {
    height: 40px;
    border-radius: 16px;
    border: 2px solid;
    box-shadow: inset 0 0 0 3px white;
    cursor: pointer;
    position: relative;
    left: 9px;
    bottom: 4px;
  }

  :last-child::-webkit-color-swatch:hover {
    border: 2px solid #342d68;
  }

  :last-child::-webkit-color-swatch:active {
    border: 2px solid #4f5ca6;
  }

  :last-child::-moz-color-swatch {
    height: 40px;
    border-radius: 16px;
    border: 2px solid;
    cursor: pointer;
  }

  :last-child::-moz-color-swatch:hover {
    border: 2px solid #342d68;
  }

  :last-child::-moz-color-swatch:active {
    border: 2px solid #4f5ca6;
  }
`;

export const ButtonContainer = styled.div`
  width: 30%;
  padding: 16px 0px;
`;
