import styled from 'styled-components';

export const EditContent = styled.div`
  width: 90vw;
  margin-bottom: 5vh;
  display: flex;
  flex: 1;
  min-height: 600px;
  align-self: center;
  background-color: #ffffff;
  padding-top: 5vh;
  padding-bottom: 2rem;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
`;

export const InputContainer = styled.div`
  width: 30%;
  max-width: 300px;
  margin-top: 56px;
  align-self: center;
  position: relative;
`;

export const CardFileContainer = styled.div`
  margin-top: 56px;
  margin-bottom: -80px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  width: 30%;
  max-width: 300px;
  padding: 16px 0px;
`;

export const IconDeleteContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: #7e779e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const IconDeleteButton = styled.div`
  max-height: 40px;
  max-width: 40px;
  background-color: #7e779e;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -24px;
  margin-top: -16px;
  padding: 10px;
  z-index: 1000;
`;

export const IconDeleteButtonForText = styled(IconDeleteButton)`
  margin-right: -24px;
  margin-top: 16px;
`;
