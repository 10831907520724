import { useQuery } from 'react-query';

import type { Quiz } from '@entities/quiz';

import { getQuizBySubtopicId } from '@services/quiz';

export function getSubtopicQuizQueryKey(subtopicId: string) {
  return ['subtopic-quiz', subtopicId];
}

export const getSubtopicQuizQueryFn = (subtopicId: string) => {
  return () => getQuizBySubtopicId(subtopicId);
};

const useSubtopicQuizData = (
  subtopicId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Quiz>(
    getSubtopicQuizQueryKey(subtopicId || ''),
    getSubtopicQuizQueryFn(subtopicId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
    },
  );
};

export default useSubtopicQuizData;
