import QuarkApiError from '@services/apis/errors/QuarkApiError';
import privateApi from '@apis/private-quark';

import type { QuizProps, QuizAddProps, UpdateQuizDto } from './quiz.d';

export const getQuizBySubtopicId = async (subtopicId: string) => {
  return new Promise<QuizProps>((resolve, reject) => {
    privateApi
      .get<QuizProps>(`old-dashboard/quiz/subtopic/${subtopicId}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const getQuiz = async (id: string) => {
  return new Promise<QuizProps>((resolve, reject) => {
    privateApi
      .get<QuizProps>(`old-dashboard/quiz/${id}`)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const addQuiz = async (request: QuizAddProps) => {
  return new Promise<QuizAddProps>((resolve, reject) => {
    privateApi
      .post<QuizAddProps>('quiz', request)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};

export const updateQuiz = async (id: string, request: UpdateQuizDto) => {
  return new Promise<QuizAddProps>((resolve, reject) => {
    privateApi
      .put<QuizAddProps>(`quiz/with-questions/${id}`, request)
      .then(({ data }) => resolve(data))
      .catch((error: QuarkApiError) => reject(error));
  });
};
