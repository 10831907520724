/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f1eff8;
`;
