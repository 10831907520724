import { useQuery } from 'react-query';

import type { Skill } from '@entities/skill';

import { getSkill } from '@services/skills';

export function getSkillQueryKey(skillId: string) {
  return ['skill', skillId];
}

export const getSkillQueryFn = (skillId: string) => {
  return () => getSkill(skillId);
};

const useSkillData = (
  skillId?: string,
  onSuccess?: () => void,
  onError?: () => void,
) => {
  return useQuery<Skill>(
    getSkillQueryKey(skillId || ''),
    getSkillQueryFn(skillId || ''),
    {
      onSuccess,
      onError,
      select: data => {
        return data;
      },
      staleTime: Infinity,
      enabled: !!skillId,
    },
  );
};

export default useSkillData;
