import styled from 'styled-components';

export const ListLearningObjectContent = styled.div`
  flex: 1;
  padding: 32px 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  max-width: 1300px;
  align-self: center;
`;

export const Views = styled.div``;
