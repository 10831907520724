import { yupResolver } from '@hookform/resolvers/yup';
import Text from '@newAtoms/Text';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '@newAtoms/TextInput';
import SubmitButton from '@newAtoms/SubmitButton';
import { useNavigate } from 'react-router-dom';
import useAppToast from '@hooks/useAppToast';
import QuarkApiError from '@services/apis/errors/QuarkApiError';
import { addTopic } from '@services/topics';
import { useQueryClient } from 'react-query';
import { getTopicsQueryKey } from '@hooks/queries/useTopicsData';
import type { AddTopicProps, AddTopicsForm } from './addTopics.d';
import AddTopicFormValidation from './yup.validation';

import { AddTopicContent, ButtonContainer, InputContainer } from './styles';

const AddTopics: React.FC<AddTopicProps> = ({ skillId }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const navigate = useNavigate();
  const { successToast, errorToast } = useAppToast();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddTopicsForm>({
    resolver: yupResolver(AddTopicFormValidation),
  });

  const onSubmit = useCallback(
    (values: AddTopicsForm) => {
      setIsLoadingSubmit(true);

      addTopic({
        title: values.title,
        index: Number(values.index),
        icon: values.icon,
        skillId,
      })
        .then(() => {
          successToast({ message: 'Tópico adicionado com sucesso!' });
          setIsLoadingSubmit(false);
          queryClient.invalidateQueries(getTopicsQueryKey(skillId || ''));
          navigate(-1);
        })
        .catch((e: QuarkApiError) => {
          errorToast({ message: e.message });
          setIsLoadingSubmit(false);
        });
    },
    [successToast, queryClient, errorToast, navigate, skillId],
  );

  return (
    <AddTopicContent>
      <Text
        color="#342d68"
        text="Criar Tópico"
        fontWeight={700}
        fontSize={32}
      />
      <InputContainer>
        <TextInput
          name="title"
          label="Título"
          value={getValues('title')}
          errorMessage={errors.title?.message}
          onChangeText={value => setValue('title', value)}
        />
        <TextInput
          name="index"
          label="Posição"
          value={getValues('index')}
          errorMessage={errors.index?.message}
          onChangeText={value => setValue('index', value)}
        />
        <TextInput
          name="icon"
          label="Ícone"
          value={getValues('icon')}
          errorMessage={errors.icon?.message}
          onChangeText={value => setValue('icon', value)}
        />
      </InputContainer>
      <ButtonContainer>
        <SubmitButton
          title="Criar"
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoadingSubmit}
        />
      </ButtonContainer>
    </AddTopicContent>
  );
};

export default AddTopics;
