import styled from 'styled-components';

export const ListContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  max-width: 1300px;
  align-self: center;
  flex-direction: column;
`;

export const Views = styled.div``;

export const Datas = styled.section`
  font-family: 'Rubik';
  font-size: 14px;
  margin: 10px 0 30px 0;
  display: flex;
  justify-content: center;

  p:first-child {
    padding-right: 20px;
  }

  p:first-child::after {
    content: '|';
    padding-left: 20px;
  }
`;

export const TextDatas = styled.p``;
