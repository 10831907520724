import axios, { AxiosError } from 'axios';

import StorageKeys from '@constants/StorageKeys';
import { storageGet, storageSet } from '@services/storage';

import { refreshTokens } from '@services/auth';
import type { JwtTokens } from '@services/auth.d';

import QuarkApiError, {
  QuarkApiErrorResponse,
} from '@apis/errors/QuarkApiError';

import { AuthErrorCode } from '@i18n/codes';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
  headers: { 'x-client-platform': 'web' },
});

api.interceptors.request.use(
  config => {
    const tokens = storageGet<JwtTokens>(StorageKeys.AUTH_TOKENS);
    if (!tokens) {
      return config;
    }

    const originalRequest = config;
    if (!originalRequest.headers) {
      return originalRequest;
    }

    originalRequest.headers.Authorization = `Bearer ${tokens.authorization_token.code}`;
    return originalRequest;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async (error: AxiosError<QuarkApiErrorResponse>) => {
    const { config, response } = error;
    const originalRequest = config;

    if (!response) {
      throw new QuarkApiError(error);
    }

    if (
      response.data.code === AuthErrorCode.INVALID_JWT &&
      originalRequest.headers?.Authorization
    ) {
      const tokens = storageGet<JwtTokens>(StorageKeys.AUTH_TOKENS);
      if (!tokens) {
        throw new QuarkApiError(error);
      }

      const newTokens = await refreshTokens(tokens.refresh_token.code);
      storageSet(StorageKeys.AUTH_TOKENS, newTokens);

      originalRequest.headers.Authorization = `Bearer ${newTokens.authorization_token.code}`;
      return axios(originalRequest).catch(newError => {
        throw new QuarkApiError(newError);
      });
    }

    throw new QuarkApiError(error);
  },
);

export default api;
